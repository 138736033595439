import React, {useEffect, useState} from 'react';
import classes from "./main.module.css";
import {text} from "../../translate";
import {useTelegram} from "../../../hooks/useTelegram";
import {useNavigate} from "react-router-dom";
import TelegramScreen from "../../common/telegramScreen/telegramScreen";
import gif1 from '../../../image/gif/cart-dark-bg.gif'
import gif2 from '../../../image/gif/cart-white-bg.gif'
import gif3 from '../../../image/gif/calc-dark-bg.gif'
import gif4 from '../../../image/gif/calc-white-bg.gif'
import {useCloudStorage} from "../../../hooks/useCloudStorage";
import PrizeDay from "../prizeDay/prizeDay";
import {CSSTransition} from "react-transition-group";
import {get_day_price} from "../../../utils/api";
import {getTimer} from "../../../utils/timer";

const Main = ({startParams, setStartParams}) => {
    const {lang, webApp} = useTelegram()
    const navigator = useNavigate()
    const {removeAll} = useCloudStorage()
    const [day, setDay] = useState(0)
    const [openPrize, setOpenPrize] = useState(false)
    const [showPrize, setShowPrize] = useState(false)
    const [openListPrizes, setOpenListPrizes] = useState(false);
    const [prizes, setPrizes] = useState([])
    const [timer, setTimer] = useState('')

    const stylesDays = [
        {color: "#4F5267", gradient: 'linear-gradient(270deg, #979BAE 0%, #46495E 100%)'},
        {color: "#D5414F", gradient: 'linear-gradient(89deg, #D23C4C 0%, #E04E57 50.5%, #F3B255 100%)'},
        {color: "#E5643C", gradient: 'linear-gradient(270deg, #FF965E 0%, #E6621B 100%)'},
        {color: "#E5932F", gradient: 'linear-gradient(270deg, #ECC55D 0%, #E48D2C 100%)'},
        {color: "#47B032", gradient: 'linear-gradient(270deg, #85D164 0%, #40AD2C 100%)'},
        {color: "#4235DF", gradient: 'linear-gradient(270deg, #8970E9 0%, #392EDE 100%)'},
        {color: "#2667D7", gradient: 'linear-gradient(270deg, #5E9FE1 0%, #1F60D6 100%)'}
    ]

    useEffect(()=>{
        const getCountPrize = async ()=>{
            const res = await get_day_price()
            console.log(res)
            setPrizes(res)
            if(res.length > 0)
                setShowPrize(true)
            //if((res.length > 0 && ['proself','jekingreat','imiyaroslav','ana_abra'].includes(webApp.initDataUnsafe?.user?.username)) || webApp.platform === 'unknown'){
            //    setShowPrize(true)
            //}else{
            //    setShowPrize(false)
            //}
        }
        getCountPrize()

        if(webApp.platform !== 'unknown')
            removeAll()
        const d = new Date();
        setDay(d.getDay())
        //setDay(5)
    },[])

    useEffect(()=>{
        if(startParams && startParams.com==='openList'){
            setOpenPrize(true)
            setOpenListPrizes(true)
        }
        if(startParams)
            setStartParams(null)
    },[startParams])

    useEffect(()=>{

        let id = setInterval(()=>{
            if(prizes.length > 0)
                setTimer(getTimer(prizes[0].date_end))
        },1000)
        return () => {
            clearInterval(id);
        };
    },[prizes])

    return (
        <TelegramScreen className={classes._} showbackbutton={false}>
            {showPrize && prizes.length > 0 && <div className={classes.prizeBlock} style={{background:stylesDays[day].gradient}} onClick={()=>setOpenPrize(true)}>
                <div className={classes.prizeBlock1}>
                    <div className={classes.prizeImgBlock}>
                        <div className={classes.prizeImg}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 6V22M12 6H8.46429C7.94332 6 7.4437 5.78929 7.07533 5.41421C6.70695 5.03914 6.5 4.53043 6.5 4C6.5 3.46957 6.70695 2.96086 7.07533 2.58579C7.4437 2.21071 7.94332 2 8.46429 2C11.2143 2 12 6 12 6ZM12 6H15.5357C16.0567 6 16.5563 5.78929 16.9247 5.41421C17.293 5.03914 17.5 4.53043 17.5 4C17.5 3.46957 17.293 2.96086 16.9247 2.58579C16.5563 2.21071 16.0567 2 15.5357 2C12.7857 2 12 6 12 6ZM20 11V18.8C20 19.9201 20 20.4802 19.782 20.908C19.5903 21.2843 19.2843 21.5903 18.908 21.782C18.4802 22 17.9201 22 16.8 22L7.2 22C6.07989 22 5.51984 22 5.09202 21.782C4.71569 21.5903 4.40973 21.2843 4.21799 20.908C4 20.4802 4 19.9201 4 18.8V11M2 7.6L2 9.4C2 9.96005 2 10.2401 2.10899 10.454C2.20487 10.6422 2.35785 10.7951 2.54601 10.891C2.75992 11 3.03995 11 3.6 11L20.4 11C20.9601 11 21.2401 11 21.454 10.891C21.6422 10.7951 21.7951 10.6422 21.891 10.454C22 10.2401 22 9.96005 22 9.4V7.6C22 7.03995 22 6.75992 21.891 6.54601C21.7951 6.35785 21.6422 6.20487 21.454 6.10899C21.2401 6 20.9601 6 20.4 6L3.6 6C3.03995 6 2.75992 6 2.54601 6.10899C2.35785 6.20487 2.20487 6.35785 2.10899 6.54601C2 6.75992 2 7.03995 2 7.6Z" stroke={stylesDays[day].color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div className={classes.prizeBlockTextBlock}>
                        <div className={classes.prizeBlockText1}>{text[lang].prizeDay.text1}</div>
                        <div className={classes.prizeBlockText2}>{text[lang].prizeDay.text2} {timer}</div>
                    </div>
                </div>
                <div className={classes.prizeCountBlock}>
                    <div className={classes.prizeCount}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M5.14962 0.792288C5.48433 0.0666332 6.51567 0.0666331 6.85038 0.792288L7.91304 3.09614C8.04946 3.39189 8.32973 3.59552 8.65316 3.63387L11.1726 3.93259C11.9662 4.02668 12.2849 5.00754 11.6982 5.55011L9.83549 7.2727C9.59637 7.49383 9.48932 7.82331 9.55279 8.14276L10.0472 10.6312C10.203 11.415 9.36862 12.0212 8.6713 11.6309L6.45742 10.3917C6.17322 10.2326 5.82678 10.2326 5.54258 10.3917L3.3287 11.6309C2.63138 12.0212 1.79701 11.415 1.95275 10.6312L2.22287 9.27177C2.27623 9.00325 2.44442 8.77151 2.68319 8.63756L6.36433 6.57232C6.62487 6.42615 6.48628 6.02769 6.19127 6.07474L2.10134 6.72711C1.81766 6.77236 1.5289 6.68491 1.318 6.48987L0.301797 5.55012C-0.28491 5.00754 0.0337889 4.02668 0.827359 3.93259L3.34684 3.63387C3.67026 3.59552 3.95054 3.39189 4.08695 3.09614L5.14962 0.792288Z" fill="white"/>
                        </svg>
                        {prizes.length > 0 ? prizes[0].price_star : 1}
                    </div>
                    <div className={classes.prizeStr}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M9 18L15 12L9 6" stroke="white" strokeWidth="2" strokeLinecap="square"/>
                        </svg>
                    </div>
                </div>
            </div>}
            <div className={classes.block+' '+(webApp.colorScheme === 'dark' && classes.block_d)} onClick={()=>navigator('shop')}>
                <div className={classes.text}>
                    {text[lang].main.text1}
                </div>
                {webApp.colorScheme === 'dark' && <img src={gif1} className={classes.gif1} alt={''}/>}
                {webApp.colorScheme === 'light' && <img src={gif2} className={classes.gif1} alt={''}/>}
                <div className={classes.block2}></div>
            </div>
            <div className={classes.block+' '+(webApp.colorScheme === 'dark' && classes.block_d)} onClick={()=>navigator('calc')}>
                <div className={classes.text}>
                    {text[lang].main.text2}
                </div>
                {webApp.colorScheme === 'dark' && <img src={gif3} className={classes.gif1} alt={''}/>}
                {webApp.colorScheme === 'light' && <img src={gif4} className={classes.gif1} alt={''}/>}
                <div className={classes.block2}></div>
            </div>
            <CSSTransition in={openPrize}
                           timeout={300}
                           classNames="block"
                           unmountOnExit
            >
                <PrizeDay day={day}
                          open={setOpenPrize}
                          lang={lang}
                          prizes={prizes}
                          openListPrizes={openListPrizes}
                          setOpenListPrizes={setOpenListPrizes}
                />
            </CSSTransition>
        </TelegramScreen>
    );
};

export default Main;