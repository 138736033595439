import React from 'react';
import classes from "./listPrizes.module.css";
import {CSSTransition} from "react-transition-group";
import {useTelegram} from "../../../../hooks/useTelegram";
import {text} from "../../../translate";
import {server} from "../../../../utils/api";

const ListPrizes = ({openListPrizes, setOpenListPrizes, prizes}) => {
    const {lang} = useTelegram()

    const colors = {
        25:'#444A53',
        40:'#ABD376',
        50:'#9580FF',
        60:'#9580FF',
        75:'#EF8A46',
        100:'#EB5149'
    }

    return (
        <CSSTransition in={openListPrizes}
                       timeout={300}
                       classNames="block"
                       unmountOnExit
        >
            <div className={classes.popup} onClick={()=>setOpenListPrizes(false)}>
                <div className={classes.close} onClick={()=>setOpenListPrizes(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M15 5L5 15M5 5L15 15" stroke={'var(--tg-theme-text-color)'} strokeOpacity="0.8" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className={classes.popupBlock}>
                    <div className={classes.popupBlock2}>
                        <div className={classes.popupText1} dangerouslySetInnerHTML={{__html:text[lang].prizeDay.text13}}></div>
                        <div className={classes.block}>
                            {prizes.map(item=>
                                <div className={classes.blockPrize} key={item.item.id}>
                                    <div className={classes.blockPrizeLeft} style={{background:colors[item.price_max]}}></div>
                                    <div className={classes.blockPrizeRight}>
                                        <div className={classes.imageBlock}>
                                            <img className={classes.image} alt={''} src={server+item.item.img}/>
                                        </div>
                                        <div className={classes.blockPrizeRightBlock2}>
                                            <div className={classes.name}>{item.item.firma} {item.item.name}</div>
                                            <div className={classes.blockPrizeRightBlock}>
                                                <div className={classes.hashrate}>{text[lang].cart.text2} <span className={classes.hashrate2}>{item.item.hashrate}</span></div>
                                                <div className={classes.sale}>{text[lang].prizeDay.text7} ${item.price_min}-${item.price_max}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.popupBlock2}>
                        <div className={classes.text2}>{text[lang].prizeDay.text14}</div>
                        <div className={classes.send} onClick={()=>{window.open(
                            `https://t.me/share/url?url=https://t.me/ImineShopBot/ImineShop?startapp=prizes=openList&text=Предложение дня!`
                        );}}>
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.3609 8.40513C14.5237 8.26563 14.605 8.19589 14.6348 8.11289C14.661 8.04004 14.661 7.96035 14.6348 7.8875C14.605 7.8045 14.5237 7.73476 14.3609 7.59526L8.71375 2.75484C8.4336 2.51471 8.29353 2.39465 8.17493 2.3917C8.07186 2.38915 7.97341 2.43443 7.90828 2.51435C7.83333 2.6063 7.83333 2.7908 7.83333 3.15978V6.02328C6.41021 6.27225 5.10773 6.99337 4.13981 8.07611C3.08455 9.25654 2.50082 10.7842 2.5 12.3675V12.7755C3.19956 11.9328 4.073 11.2512 5.06051 10.7775C5.93113 10.3598 6.87228 10.1124 7.83333 10.0472V12.8406C7.83333 13.2096 7.83333 13.3941 7.90828 13.486C7.97341 13.566 8.07186 13.6112 8.17493 13.6087C8.29353 13.6057 8.4336 13.4857 8.71375 13.2455L14.3609 8.40513Z" stroke={'var(--tg-theme-link-color)'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            {text[lang].calc.text21}
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default ListPrizes;