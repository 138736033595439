import React, {useEffect, useState} from 'react';
import {useTelegram} from "../../../hooks/useTelegram";
import {text} from "../../translate";
import classes from "./shop.module.css";
import {get_models, server} from "../../../utils/api";
import TelegramScreen from "../../common/telegramScreen/telegramScreen";
import PrizeFooter from "../../common/prizeFooter/prizeFooter";
import {useNavigate} from "react-router-dom";

const Shop = () => {
    const {lang, webApp} = useTelegram()
    const [models, setModels] = useState([])
    const navigator = useNavigate()

    useEffect(()=>{
        const getModels = async ()=>{
            let res = await get_models()
            res = res.filter(item=>item.in_shop)
            setModels(res)
        }
        getModels()
    },[])

    return (
        <TelegramScreen className={classes._} showbackbutton={true}>
            <div className={classes.searchBlock} onClick={()=>navigator('/search')}>
                <div className={classes.searchBlock2}>
                    <svg className={classes.searchIcon} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6V26C32 29.3137 29.3137 32 26 32H6C2.68629 32 0 29.3137 0 26V6Z" fill="#74BE60"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.2499 7.66602C10.9132 7.66602 9.66659 7.86167 8.72423 8.20434C8.69499 8.21497 8.66588 8.22581 8.6369 8.23685C8.20222 8.40247 7.79832 8.61471 7.4835 8.88537C7.15039 9.17175 6.83325 9.60164 6.83325 10.166V11.416C6.83325 11.9804 7.15039 12.4103 7.4835 12.6967C7.8193 12.9854 8.25647 13.2076 8.72423 13.3777C9.66659 13.7204 10.9132 13.916 12.2499 13.916C12.4433 13.916 12.6349 13.9119 12.8239 13.9039C12.9405 13.8989 12.9988 13.8964 13.0511 13.88C13.1011 13.8643 13.1377 13.8453 13.1794 13.8135C13.2229 13.7803 13.2673 13.7227 13.3561 13.6075C13.5338 13.377 13.7237 13.1872 13.897 13.0382C14.4405 12.5709 15.0808 12.2634 15.6547 12.0547C16.0984 11.8933 16.5865 11.7624 17.0995 11.6621C17.3102 11.6209 17.4156 11.6003 17.4874 11.5505C17.5528 11.5051 17.5995 11.4484 17.6315 11.3755C17.6666 11.2955 17.6666 11.1935 17.6666 10.9897C17.6666 10.7083 17.6666 10.3841 17.6666 10.166C17.6666 9.60164 17.3494 9.17175 17.0163 8.88537C16.6805 8.59666 16.2434 8.37444 15.7756 8.20434C14.8333 7.86167 13.5866 7.66602 12.2499 7.66602Z" fill="white"/>
                        <path d="M12.6661 15.993C12.6662 15.7651 12.4778 15.5827 12.2499 15.5827C10.9132 15.5827 9.66659 15.387 8.72423 15.0444C8.25647 14.8743 7.8193 14.652 7.4835 14.3633C7.36109 14.2581 7.24084 14.1335 7.13769 13.9899C7.10513 13.9446 7.05355 13.916 6.99775 13.916C6.9069 13.916 6.83325 13.9897 6.83325 14.0805V15.5827C6.83325 16.1471 7.15039 16.5769 7.4835 16.8633C7.8193 17.152 8.25647 17.3743 8.72423 17.5444C9.66659 17.887 10.9132 18.0827 12.2499 18.0827C12.2533 18.0827 12.2549 18.0827 12.2601 18.0826C12.4774 18.0772 12.6611 17.8934 12.6664 17.6761C12.6665 17.671 12.6665 17.6672 12.6665 17.6596C12.6662 17.1041 12.6656 16.5485 12.6661 15.993Z" fill="white"/>
                        <path d="M12.6666 20.1597C12.6666 19.9316 12.478 19.7493 12.2499 19.7493C10.9132 19.7493 9.66659 19.5537 8.72423 19.211C8.25647 19.0409 7.8193 18.8187 7.4835 18.53C7.36109 18.4248 7.24084 18.3001 7.13769 18.1566C7.10513 18.1113 7.05355 18.0827 6.99775 18.0827C6.9069 18.0827 6.83325 18.1563 6.83325 18.2472V20.166C6.83325 20.7304 7.15039 21.1603 7.4835 21.4467C7.8193 21.7354 8.25647 21.9576 8.72423 22.1277C9.66659 22.4704 10.9132 22.666 12.2499 22.666C12.2659 22.666 12.2745 22.666 12.2745 22.666C12.5412 22.6652 12.7127 22.4764 12.688 22.2108C12.688 22.2108 12.6857 22.1866 12.6812 22.1395C12.6716 22.04 12.6666 21.9377 12.6666 21.8327V20.1597Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.8392 12.8067C18.4357 12.7149 19.0816 12.666 19.7499 12.666C21.0866 12.666 22.3333 12.8617 23.2756 13.2043C23.3048 13.215 23.334 13.2258 23.3629 13.2368C23.7976 13.4025 24.2015 13.6147 24.5163 13.8854C24.8494 14.1717 25.1666 14.6016 25.1666 15.166V16.8327C25.1666 17.3971 24.8494 17.8269 24.5163 18.1133C24.1805 18.402 23.7434 18.6243 23.2756 18.7944C22.3332 19.137 21.0866 19.3327 19.7499 19.3327C18.4132 19.3327 17.1666 19.137 16.2242 18.7944C15.7565 18.6243 15.3193 18.402 14.9835 18.1133C14.6504 17.8269 14.3333 17.3971 14.3333 16.8327C14.3333 16.2747 14.3333 15.7153 14.3333 15.166C14.3333 14.6016 14.6504 14.1717 14.9835 13.8854C15.3193 13.5967 15.7565 13.3744 16.2242 13.2043C16.2831 13.1829 16.3432 13.1621 16.4044 13.1418C16.7854 13.0158 17.2103 12.9124 17.6666 12.8347C17.7236 12.8249 17.7812 12.8156 17.8392 12.8067Z" fill="white"/>
                        <path d="M14.4978 19.3327C14.4069 19.3327 14.3333 19.4063 14.3333 19.4972V21.416C14.3333 21.9804 14.6504 22.4103 14.9835 22.6967C15.3193 22.9854 15.7565 23.2076 16.2242 23.3777C17.1666 23.7204 18.4132 23.916 19.7499 23.916C21.0866 23.916 22.3333 23.7204 23.2756 23.3777C23.7434 23.2076 24.1805 22.9854 24.5163 22.6967C24.8494 22.4103 25.1666 21.9804 25.1666 21.416V19.4972C25.1666 19.4063 25.0929 19.3327 25.0021 19.3327C24.9463 19.3327 24.8947 19.3613 24.8621 19.4066C24.759 19.5501 24.6387 19.6748 24.5163 19.78C24.1805 20.0687 23.7434 20.2909 23.2756 20.461C22.3332 20.8037 21.0866 20.9993 19.7499 20.9993C18.4132 20.9993 17.1666 20.8037 16.2242 20.461C15.7565 20.2909 15.3193 20.0687 14.9835 19.78C14.8611 19.6748 14.7408 19.5501 14.6377 19.4066C14.6051 19.3613 14.5536 19.3327 14.4978 19.3327Z" fill="white"/>
                    </svg>
                    <div>
                        <div className={classes.searchText1}>{text[lang].search.text1}</div>
                        <div className={classes.searchText2}>{text[lang].search.text2}</div>
                    </div>
                </div>
                <svg className={classes.searchIcon2} xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                    <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#999999"/>
                </svg>
            </div>
            <div className={classes.emptyBlock}></div>
            <div className={classes.text1}>{text[lang].shop.text1}</div>
            <div className={classes.blockModels}>
                {models.map((item,number) =>
                    <div key={item.id} className={classes.block+' '+(number!==0 && classes.block_1)}
                        onClick={()=>window.location.href='/shop/models?id='+item.id}
                    >
                        <div className={classes.imageModelsBlock}>
                            {(webApp.colorScheme === 'dark' && item.img_dark) && <img alt='' className={classes.imageModels} src={server+item.img_dark}/>}
                            {(webApp.colorScheme === 'light' && item.img_light) && <img alt='' className={classes.imageModels} src={server+item.img_light}/>}
                        </div>
                        <div className={classes.block_text}>{item.name}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                            <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#999999"/>
                        </svg>
                    </div>
                )}
            </div>
            <PrizeFooter/>
        </TelegramScreen>
    );
};

export default Shop;