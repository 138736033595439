export function getTimer(timeT){
    let time = new Date(timeT+' 23:59:59');
    //time.setHours(23, 59, 59, 999);
    const daysT = Math.trunc((time - Date.now()) / (1000 * 60 * 60 * 24))
    const hoursT = Math.trunc((time - Date.now() - daysT * 1000 * 60 * 60 * 24) / (1000 * 60 * 60))
    let minutesT = Math.trunc((time - Date.now() - daysT * 1000 * 60 * 60 * 24 - hoursT * 1000 * 60 * 60) / (1000 * 60))
    let secT = Math.trunc((time - Date.now() - daysT * 1000 * 60 * 60 * 24 - hoursT * 1000 * 60 * 60 - minutesT * 60 * 1000) / 1000)
    if(secT < 10)
        secT = '0'+secT.toString()
    if(minutesT < 10)
        minutesT = '0'+minutesT.toString()
    return (hoursT+daysT*24).toString()+':'+minutesT.toString()+':'+secT.toString()
}