import React from 'react';
import classes from "./popup.module.css";
import {CSSTransition} from "react-transition-group";

const Popup = ({openPopup,setOpenPopup, setOpenPopup2, price}) => {
    return (
        <CSSTransition in={openPopup}
                       timeout={300}
                       classNames="block"
                       unmountOnExit
        >
            <div className={classes.popup} onClick={()=>setOpenPopup(false)}>
                <div className={classes.close} onClick={()=>setOpenPopup(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M15 5L5 15M5 5L15 15" stroke={'var(--tg-theme-text-color)'} strokeOpacity="0.8" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className={classes.popupBlock}>
                    <div className={classes.popupText1}>Условия розыгрыша</div>
                    <div className={classes.popupText2}>
                        <div>
                            <div className={classes.popupText2_1}>Порядок участия</div>
                            <ol className={classes.popupText2_2}>
                                <li>Участие в розыгрыше осуществляется путем вращения рулетки.</li>
                                <li>Для каждого вращения необходимо использовать {price} звезд(у) (внутренняя валюта Telegram).</li>
                                <li>Размер скидки определяется случайным образом при каждом вращении и может составлять <span style={{fontWeight:700}}>от 25 до 100$.</span></li>
                            </ol>
                        </div>
                        <div>
                            <div className={classes.popupText2_1}>Условия предоставления скидки</div>
                            <ul className={classes.popupText2_2}>
                                <li>Скидка, выигранная в рулетке, обновляется ежедневно и действует только в течение заданного в времени.</li>
                                <li>Скидка распространяется на одну покупку и не может быть объединена с другими акциями или предложениями.</li>
                                <li>Участники могут совершать неограниченное количество вращений в течение одного дня.</li>
                                <li>Крутить колесо можно бесконечное кол-во раз. <span style={{fontWeight:700}}>Один прокрут колеса = получение скидки на 1шт (ASIC-майнер).</span>
                                    Нельзя получить суммарную скидку со всех прокрутов колеса на 1-у единицу оборудования. Воспользоваться полученной скидкой можно в течение заданного времени.</li>
                            </ul>
                        </div>
                        <div>
                            <div className={classes.popupText2_1}>Примечания</div>
                            <ul className={classes.popupText2_2}>
                                <li>Организатор оставляет за собой право изменять условия проведения розыгрыша.</li>
                                <li>По вопросам использования скидки или техническим вопросам обращайтесь в службу поддержки.</li>
                            </ul>
                        </div>
                    </div>
                    <div className={classes.text3} onClick={()=>{setOpenPopup(false);setOpenPopup2(true)}}>Все условия и положения</div>
                </div>
            </div>

        </CSSTransition>
    );
};

export default Popup;