import React, {useEffect, useRef, useState} from 'react';
import classes from "./calc.module.css";
import classes2 from '../shop/cart/cart.module.css'
import TelegramScreen from "../../common/telegramScreen/telegramScreen";
import {useTelegram} from "../../../hooks/useTelegram";
import {text} from "../../translate";
import {
    get_course,
    get_courseBTC, get_item,
    get_items,
    get_models,
    get_profit,
    get_stat,
    server,
    tracking2
} from "../../../utils/api";
import {useNavigate} from "react-router-dom";
import {toRub} from "../../../utils/money";
import Loading from "../../common/loading/loading";
import {CSSTransition} from "react-transition-group";

const Calc = ({step, setStep, startParams, setStartParams}) => {
    const navigate = useNavigate();
    const {lang, webApp} = useTelegram()
    const [loading, setLoading] = useState(false)
    const [valueEl, setValueEl] = useState('')
    const [models, setModels] = useState([])
    const [model, setModel] = useState({})
    const [items, setItems] = useState([])
    const [items2, setItems2] = useState([])
    const [item, setItem] = useState({})
    const [item2, setItem2] = useState({})
    const [count, setCount] = useState('')
    const [allItems, setAllItems] = useState([])
    const [days, setDays] = useState(1)
    const [energyFee, setEnergyFee] = useState(0)
    const [courseBTC, setCourseBTC] = useState(0)
    const [courseUSD, setCourseUSD] = useState(0)
    const [courseBTCRub, setCourseBTCRub] = useState(0)
    const [valueBTC, setValueBTC] = useState(0)
    const [profit, setProfit] = useState(0)
    const [profitCount, setProfitCount] = useState(0)
    const [textSend, setTextSend] = useState('')
    const [openSendProfit, setOpenSendProfit] = useState(false)
    const [copyLinkText, setCopyLinkText] = useState(false)
    const [textButton, setTextButton] = useState( text[lang].payment.text14)
    const ref = useRef();
    const ref2 = useRef();

    useEffect(()=>{
        const getStartParams = async (c)=> {
            console.log(startParams)
            let temp = []
            if (startParams && startParams.items) {
                for (const sp of startParams.items.split('-')) {
                    const res = await get_item(sp.split('_')[0])
                    console.log(res)
                    temp.push({item:res, count: parseInt(sp.split('_')[1])})
                }
                setAllItems(temp)
                let el = startParams.el.slice(1).replace('T',',')
                if(lang === 'ru' && startParams.el[0] === 'D')
                    el = parseFloat(el.replace(',','.')) * c
                if(lang !== 'ru' && startParams.el[0] === 'R')
                    el = parseFloat(el.replace(',','.')) / c
                el = el.toString().replace('.',',')
                el = el.slice(0,el.indexOf(',')===-1?el.length:(el.indexOf(',')+3))
                setValueEl(el)
                if (el.indexOf(',') !== -1)
                    ref.current.style.width = el.length * 18 + 'px';
                else
                    ref.current.style.width = el.length * 18 + 8 + 'px';
                setStep(5)
            }
            setStartParams({})
        }
        const getModels = async ()=>{
            setLoading(true)
            setModels(await get_models())
            const c = await get_course()
            const btc = await get_courseBTC()
            const prof = await get_profit()
            setCourseBTCRub(btc)
            setCourseUSD(c)
            setProfitCount(prof)
            if(lang === 'ru')
                setCourseBTC(btc)
            else
                setCourseBTC(btc / c)
            setValueBTC(await get_stat())
            await getStartParams(c)
            setLoading(false)
        }
        getModels()
    },[])

    useEffect(()=>{
        const getItems = async ()=>{
            if(model.id){
                setLoading(true)
                let res = await get_items(model.id)
                res = res.filter(item=>item.in_calc)
                let temp = []
                let temp2 = []
                res.forEach(item=>{
                    if(temp.indexOf(item.name) === -1){
                        temp.push(item.name)
                        temp2.push(item)
                    }
                })
                setItems(temp2)
                setLoading(false)
            }
        }
        getItems()
    },[model])

    useEffect(()=>{
        const getItems = async ()=>{
            if(model.id){
                setLoading(true)
                let res = await get_items(model.id)
                res = res.filter(item=>item.in_calc)
                let temp = []
                res.forEach(i=>{
                    if(i.name === item.name){
                        temp.push(i)
                    }
                })
                setItems2(temp)
                setLoading(false)
            }
        }
        getItems()
    },[item])

    useEffect(()=>{
        setCount('')
        //if(step === 2){
        //    setItems2([])
        //}
    },[step])

    useEffect(()=>{
        let energyFeeT = 0;
        let prof = 0;
        let profT = 0;
        allItems.forEach(item =>{
            energyFeeT += (parseFloat(valueEl.replace(',','.')) ? parseFloat(valueEl.replace(',','.')) : 0) * 24 * item.count * item.item.power / 1000
            if(item.item.type === 'BTC'){
                prof += item.item.hashrate.split(' ')[0] * item.count * valueBTC * Math.pow(10,12)
            } else {
                profT = item.item.hashrate.split(' ')[0] * profitCount[item.item.type] * courseUSD / courseBTCRub * item.count
                if(item.item.type === 'kHeavyHash' && item.item.hashrate.split(' ')[1] === 'th'){
                    profT = profT * 1000
                }
                if(item.item.type === 'Blake3' && item.item.hashrate.split(' ')[1] === 'gh'){
                    profT = profT / 1000
                }
                prof += profT
            }
            /*if(item.item.type === 'Ethash'){
                prof += item.item.hashrate.split(' ')[0] * 556.3 / 2400 / courseBTCRub * item.count
            }
            if(item.item.type === 'Scrypt'){
                prof += item.item.hashrate.split(' ')[0] * 955 / 9500 / courseBTCRub * item.count
            }
            if(item.item.type === 'Eaglesong'){
                prof += item.item.hashrate.split(' ')[0] * 1176.54 / 63.5 / courseBTCRub * item.count
            }
            if(item.item.type === 'kHeavyHash'){
                prof += item.item.hashrate.split(' ')[0] * 1376.72 / 166 / courseBTCRub * item.count
            }
            if(item.item.type === 'X11'){
                prof += item.item.hashrate.split(' ')[0] * 1542 / 1779 / courseBTCRub * item.count
            }
            if(item.item.type === 'Handshake'){
                prof += item.item.hashrate.split(' ')[0] * profitCount['Handshake'] * courseUSD / courseBTCRub * item.count
            }*/
        })
        setProfit(prof)
        setEnergyFee(energyFeeT);
        let temp = []
        for (const t of allItems) {
            temp.push(t.item.id+'_'+t.count)
        }
        setTextSend('https://t.me/ImineShopBot/ImineShop?startapp=calc='+(lang==='ru'?'R':'D')+valueEl.replace(',','T')+'='+temp.join('-'))

    },[allItems, valueEl])

    function addItem(){
        if(count > 0){
            let temp = []
            let flag = true
            allItems.forEach(i=>{
                if(i.item === item2){
                    temp.push({item:item2, count:i.count + count})
                    flag = false
                }else{
                    temp.push(i)
                }
            })
            if(flag)
                temp.push({item:item2, count:count})
            setAllItems(temp)
            setCount(0)
            setStep(0)
        }
    }

    function deleteItem(it){
        let temp = []
        allItems.forEach(i=>{
            if(it.item !== i.item)
                temp.push(i)
        })
        setAllItems(temp)
    }

    function plusItem(it){
        let temp = []
        allItems.forEach(i=>{
            if(i.item === it.item)
                temp.push({item:i.item, count:parseInt(i.count)+1})
            else
                temp.push(i)
        })
        setAllItems(temp)
    }

    function minusItem(it){
        let temp = []
        allItems.forEach(i=>{
            if(i.item === it.item){
                if(parseInt(i.count) > 1)
                    temp.push({item:i.item, count:parseInt(i.count)-1})
            }
            else
                temp.push(i)
        })
        setAllItems(temp)
    }

    function setCountItem(it, c){
        let temp = []
        allItems.forEach(i=>{
            if(i.item === it.item)
                temp.push({item:i.item, count:c})
            else
                temp.push(i)
        })
        setAllItems(temp)
    }

    function goShop(){
        setStep(0);
        let flag = true;
        allItems.forEach(i => {
            if(i.item.in_shop){
                webApp.CloudStorage.setItem(i.item.id, parseInt(i.count),()=>{})
                webApp.MainButton.show()
            }else{
                flag = false
            }
        })
        if(flag){
            navigate('/cart')
        }else{
            setStep(6)
        }
    }

    async function calcStart(){
        if(allItems.length > 0) {
            setStep(5)
            const res = await tracking2(
                webApp.initDataUnsafe?.user.id,
                allItems
                )
            console.log(res)
        }
    }

    function sendCalc(){
        let textAreaValue = "Посмотри, какая прибыль:"
        window.open(
            `https://t.me/share/url?url=${textSend}&text=${textAreaValue}`
        );
        setOpenSendProfit(false)
    }

    function copyLink(){
        setCopyLinkText(true)
        navigator.clipboard.writeText(textSend);
        setTimeout(()=>setCopyLinkText(false), 3000)
    }

    return (
        <TelegramScreen showbackbutton={true}>
            <div className={classes.searchText0}>{text[lang].calc.text26}</div>
            <div className={classes.mainBlock}>
                {allItems.map(i =>
                    <div className={classes2.block}>
                        <div className={classes2.imageBlock}>
                            <img className={classes2.image} alt={''} src={server+i.item.img}/>
                        </div>
                        <div className={classes2.blockInfo}>
                            <div className={classes2.name}>{i.item.firma} {i.item.name}</div>
                            <div className={classes2.hashrate}>{text[lang].cart.text2} <span className={classes2.hashrate2}>{i.item.hashrate}</span></div>
                            <div className={classes2.buttonBlock}>
                                <div className={classes2.delete} onClick={()=>deleteItem(i)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M10.6667 3.99992V3.46659C10.6667 2.71985 10.6667 2.34648 10.5213 2.06126C10.3935 1.81038 10.1895 1.60641 9.93865 1.47858C9.65344 1.33325 9.28007 1.33325 8.53333 1.33325H7.46667C6.71993 1.33325 6.34656 1.33325 6.06135 1.47858C5.81046 1.60641 5.60649 1.81038 5.47866 2.06126C5.33333 2.34648 5.33333 2.71985 5.33333 3.46659V3.99992M2 3.99992H14M12.6667 3.99992V11.4666C12.6667 12.5867 12.6667 13.1467 12.4487 13.5746C12.2569 13.9509 11.951 14.2569 11.5746 14.4486C11.1468 14.6666 10.5868 14.6666 9.46667 14.6666H6.53333C5.41323 14.6666 4.85318 14.6666 4.42535 14.4486C4.04903 14.2569 3.74307 13.9509 3.55132 13.5746C3.33333 13.1467 3.33333 12.5867 3.33333 11.4666V3.99992" stroke="var(--tg-theme-text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div className={classes2.countBlock}>
                                    <div className={classes2.minus} onClick={()=>minusItem(i)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M3.33334 8H12.6667" stroke="var(--tg-theme-text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <input className={classes2.inputNumber} value={i.count} type={"number"} onChange={e=>setCountItem(i,e.target.value)} />
                                    <div className={classes2.plus} onClick={()=>plusItem(i)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8 3.33325V12.6666M3.33333 7.99992H12.6667" stroke="var(--tg-theme-text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className={classes.text1} onClick={()=>setStep(1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M8.89823 0.256847L9 0.25C9.3797 0.25 9.69349 0.532154 9.74315 0.898229L9.75 1V8.25H17C17.3797 8.25 17.6935 8.53215 17.7432 8.89823L17.75 9C17.75 9.3797 17.4678 9.69349 17.1018 9.74315L17 9.75H9.75V17C9.75 17.3797 9.46785 17.6935 9.10177 17.7432L9 17.75C8.6203 17.75 8.30651 17.4678 8.25685 17.1018L8.25 17V9.75H1C0.620304 9.75 0.306509 9.46785 0.256847 9.10177L0.25 9C0.25 8.6203 0.532154 8.30651 0.898229 8.25685L1 8.25H8.25V1C8.25 0.620304 8.53215 0.306509 8.89823 0.256847L9 0.25L8.89823 0.256847Z" fill="var(--tg-theme-link-color)"/>
                    </svg>
                    {text[lang].calc.text1}
                </div>
                <div className={classes.blockEl} onClick={()=>ref.current.focus()}>
                    <div className={classes.text2}>{text[lang].calc.text2}</div>
                    <div className={classes.blockEl2}>
                        <input type={"text"}
                               ref={ref}
                               className={classes.inputEl}
                               value={valueEl}
                               pattern="\\d*"
                               inputmode="decimal"
                               placeholder={'0'}
                               onChange={(e)=> {
                                   if(e.target.value.length === 0){
                                       setValueEl('')
                                   }else{
                                       if (e.target.value.length < 6) {
                                           let last = e.target.value[e.target.value.length - 1]
                                           const alf = '1234567890,.'
                                           if(e.target.value === '.' || e.target.value === ','){
                                               setValueEl('0,')
                                           }else{
                                               if (alf.indexOf(last) !== -1) {
                                                   if((last === '.' || last === ',') && (e.target.value.slice(0,-1).indexOf('.') !== -1 || e.target.value.slice(0,-1).indexOf(',') !== -1)){

                                                   }else{
                                                       if (e.target.value.indexOf(',') !== -1 || e.target.value.indexOf('.') !== -1)
                                                           ref.current.style.width = e.target.value.length * 20 + 'px';
                                                       else
                                                           ref.current.style.width = e.target.value.length * 20 + 10 + 'px';
                                                       setValueEl(e.target.value)
                                                   }
                                               }
                                           }
                                       }
                                   }

                                   }}
                        />
                        <div className={classes.text3}>{text[lang].calc.text3}</div>
                    </div>
                </div>
                <div className={classes.emptyBlock}></div>
                <div className={classes.searchText0}>{text[lang].search.text17}</div>
                <div className={classes.searchBlock} onClick={()=>navigate('/search')}>
                    <div className={classes.searchBlock2}>
                        <svg className={classes.searchIcon} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6V26C32 29.3137 29.3137 32 26 32H6C2.68629 32 0 29.3137 0 26V6Z" fill="#74BE60"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.2499 7.66602C10.9132 7.66602 9.66659 7.86167 8.72423 8.20434C8.69499 8.21497 8.66588 8.22581 8.6369 8.23685C8.20222 8.40247 7.79832 8.61471 7.4835 8.88537C7.15039 9.17175 6.83325 9.60164 6.83325 10.166V11.416C6.83325 11.9804 7.15039 12.4103 7.4835 12.6967C7.8193 12.9854 8.25647 13.2076 8.72423 13.3777C9.66659 13.7204 10.9132 13.916 12.2499 13.916C12.4433 13.916 12.6349 13.9119 12.8239 13.9039C12.9405 13.8989 12.9988 13.8964 13.0511 13.88C13.1011 13.8643 13.1377 13.8453 13.1794 13.8135C13.2229 13.7803 13.2673 13.7227 13.3561 13.6075C13.5338 13.377 13.7237 13.1872 13.897 13.0382C14.4405 12.5709 15.0808 12.2634 15.6547 12.0547C16.0984 11.8933 16.5865 11.7624 17.0995 11.6621C17.3102 11.6209 17.4156 11.6003 17.4874 11.5505C17.5528 11.5051 17.5995 11.4484 17.6315 11.3755C17.6666 11.2955 17.6666 11.1935 17.6666 10.9897C17.6666 10.7083 17.6666 10.3841 17.6666 10.166C17.6666 9.60164 17.3494 9.17175 17.0163 8.88537C16.6805 8.59666 16.2434 8.37444 15.7756 8.20434C14.8333 7.86167 13.5866 7.66602 12.2499 7.66602Z" fill="white"/>
                            <path d="M12.6661 15.993C12.6662 15.7651 12.4778 15.5827 12.2499 15.5827C10.9132 15.5827 9.66659 15.387 8.72423 15.0444C8.25647 14.8743 7.8193 14.652 7.4835 14.3633C7.36109 14.2581 7.24084 14.1335 7.13769 13.9899C7.10513 13.9446 7.05355 13.916 6.99775 13.916C6.9069 13.916 6.83325 13.9897 6.83325 14.0805V15.5827C6.83325 16.1471 7.15039 16.5769 7.4835 16.8633C7.8193 17.152 8.25647 17.3743 8.72423 17.5444C9.66659 17.887 10.9132 18.0827 12.2499 18.0827C12.2533 18.0827 12.2549 18.0827 12.2601 18.0826C12.4774 18.0772 12.6611 17.8934 12.6664 17.6761C12.6665 17.671 12.6665 17.6672 12.6665 17.6596C12.6662 17.1041 12.6656 16.5485 12.6661 15.993Z" fill="white"/>
                            <path d="M12.6666 20.1597C12.6666 19.9316 12.478 19.7493 12.2499 19.7493C10.9132 19.7493 9.66659 19.5537 8.72423 19.211C8.25647 19.0409 7.8193 18.8187 7.4835 18.53C7.36109 18.4248 7.24084 18.3001 7.13769 18.1566C7.10513 18.1113 7.05355 18.0827 6.99775 18.0827C6.9069 18.0827 6.83325 18.1563 6.83325 18.2472V20.166C6.83325 20.7304 7.15039 21.1603 7.4835 21.4467C7.8193 21.7354 8.25647 21.9576 8.72423 22.1277C9.66659 22.4704 10.9132 22.666 12.2499 22.666C12.2659 22.666 12.2745 22.666 12.2745 22.666C12.5412 22.6652 12.7127 22.4764 12.688 22.2108C12.688 22.2108 12.6857 22.1866 12.6812 22.1395C12.6716 22.04 12.6666 21.9377 12.6666 21.8327V20.1597Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M17.8392 12.8067C18.4357 12.7149 19.0816 12.666 19.7499 12.666C21.0866 12.666 22.3333 12.8617 23.2756 13.2043C23.3048 13.215 23.334 13.2258 23.3629 13.2368C23.7976 13.4025 24.2015 13.6147 24.5163 13.8854C24.8494 14.1717 25.1666 14.6016 25.1666 15.166V16.8327C25.1666 17.3971 24.8494 17.8269 24.5163 18.1133C24.1805 18.402 23.7434 18.6243 23.2756 18.7944C22.3332 19.137 21.0866 19.3327 19.7499 19.3327C18.4132 19.3327 17.1666 19.137 16.2242 18.7944C15.7565 18.6243 15.3193 18.402 14.9835 18.1133C14.6504 17.8269 14.3333 17.3971 14.3333 16.8327C14.3333 16.2747 14.3333 15.7153 14.3333 15.166C14.3333 14.6016 14.6504 14.1717 14.9835 13.8854C15.3193 13.5967 15.7565 13.3744 16.2242 13.2043C16.2831 13.1829 16.3432 13.1621 16.4044 13.1418C16.7854 13.0158 17.2103 12.9124 17.6666 12.8347C17.7236 12.8249 17.7812 12.8156 17.8392 12.8067Z" fill="white"/>
                            <path d="M14.4978 19.3327C14.4069 19.3327 14.3333 19.4063 14.3333 19.4972V21.416C14.3333 21.9804 14.6504 22.4103 14.9835 22.6967C15.3193 22.9854 15.7565 23.2076 16.2242 23.3777C17.1666 23.7204 18.4132 23.916 19.7499 23.916C21.0866 23.916 22.3333 23.7204 23.2756 23.3777C23.7434 23.2076 24.1805 22.9854 24.5163 22.6967C24.8494 22.4103 25.1666 21.9804 25.1666 21.416V19.4972C25.1666 19.4063 25.0929 19.3327 25.0021 19.3327C24.9463 19.3327 24.8947 19.3613 24.8621 19.4066C24.759 19.5501 24.6387 19.6748 24.5163 19.78C24.1805 20.0687 23.7434 20.2909 23.2756 20.461C22.3332 20.8037 21.0866 20.9993 19.7499 20.9993C18.4132 20.9993 17.1666 20.8037 16.2242 20.461C15.7565 20.2909 15.3193 20.0687 14.9835 19.78C14.8611 19.6748 14.7408 19.5501 14.6377 19.4066C14.6051 19.3613 14.5536 19.3327 14.4978 19.3327Z" fill="white"/>
                        </svg>
                        <div>
                            <div className={classes.searchText1}>{text[lang].search.text1}</div>
                            <div className={classes.searchText2}>{text[lang].search.text2}</div>
                        </div>
                    </div>
                    <svg className={classes.searchIcon2} xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                        <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#999999"/>
                    </svg>
                </div>
            </div>
            <div className={allItems.length > 0 ? classes.footer : classes.footer2} onClick={calcStart}>
                {textButton}
            </div>
            <CSSTransition in={step === 1}
                           timeout={300}
                           classNames="block2"
                           unmountOnExit
            >
                <div className={classes.popup}>
                    <div className={classes.text1_1}>{text[lang].shop.text1}</div>
                    <div className={classes.blockModels}>
                        {models.map((item,number) =>
                            <div key={item.id} className={classes.block+' '+(number!==0 && classes.block_1)}
                                 onClick={()=>{setModel(item); setStep(2)}}
                            >
                                <div className={classes.imageModelsBlock}>
                                    {(webApp.colorScheme === 'dark' && item.img_dark) && <img alt='' className={classes.imageModels} src={server+item.img_dark}/>}
                                    {(webApp.colorScheme === 'light' && item.img_light) && <img alt='' className={classes.imageModels} src={server+item.img_light}/>}
                                </div>
                                <div className={classes.block_text}>{item.name}</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                                    <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#999999"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={step === 2}
                           timeout={30}
                           classNames="block2"
                           unmountOnExit
            >
                <div className={classes.popup}>
                    <div className={classes.text1_1}>{text[lang].calc.text4}<span className={classes.text4}>{model.name}</span>{text[lang].calc.text5}</div>
                    <div className={classes.blockModels}>
                        {items.map((item,number) =>
                            <div key={item.id} className={classes.block+' '+(number!==0 && classes.block_1)}
                                 onClick={()=>{setItem(item); setStep(3)}}
                            >
                                <div className={classes.imageModelsBlock}>
                                    <img alt='' className={classes.imageModels} src={server+item.img}/>
                                </div>
                                <div className={classes.block_text}>{item.name}</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                                    <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#999999"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={step === 3}
                           timeout={30}
                           classNames="block2"
                           unmountOnExit
            >
                <div className={classes.popup}>
                    <div className={classes.text1_1}>{text[lang].calc.text6}<div className={classes.text4}>{item.firma} {item.name}</div></div>
                    <div className={classes.blockModels}>
                        {items2.map((i,number) =>
                            <div style={{height:'46px'}} key={i.id} className={classes.block+' '+(number!==0 && classes.block_1)}
                                 onClick={()=>{setItem2(i); setStep(4)}}
                            >
                                <div className={classes.block_text}>{i.hashrate}</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                                    <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#999999"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={step === 4}
                           timeout={30}
                           classNames="block2"
                           unmountOnExit
            >
                <div className={classes.popup2}>
                    <div className={classes.mainBlock} style={{padding: '0 16px'}}>
                        <div className={classes.text1_1} style={{marginTop:'40px'}}>{text[lang].calc.text7}</div>
                        <div className={classes.blockModels} onClick={()=>ref2.current.focus()}>
                            <div className={classes.inputCountBlock}>
                                <input className={classes.inputCount}
                                       ref={ref2}
                                       type={"number"}
                                       value={count}
                                       placeholder={0}
                                       pattern="\\d*"
                                       inputmode="decimal"
                                       onChange={(e)=>{
                                           if(e.target.value.length < 6){
                                               ref2.current.style.width = e.target.value.length * 22 + 8 + 'px';
                                               setCount(parseInt(e.target.value))
                                           }
                                       }}/>
                                <div className={classes.inputCountNumber}>{text[lang].calc.text8}</div>
                            </div>
                        </div>
                    </div>
                    <div className={count > 0 ? classes.footer : classes.footer2} onClick={addItem}>
                        {text[lang].payment.text14}
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={step === 5}
                           timeout={300}
                           classNames="block"
                           unmountOnExit
            >
                <div className={classes.popup2}>
                    <div className={classes.mainBlock2}>
                        <div className={classes.text5} style={{marginTop:'24px'}}>{text[lang].calc.text9}</div>
                        <div className={classes.text6}>{toRub(((profit - energyFee / courseBTC) * courseBTC * days).toFixed(2))}
                            {days===1 && <span className={classes.text7}>{text[lang].calc.text10}</span>}
                            {days===7 && <span className={classes.text7}>{text[lang].calc.text10_1}</span>}
                            {days===30.5 && <span className={classes.text7}>{text[lang].calc.text10_2}</span>}
                        </div>
                        <div className={classes.blockProfit}>
                            <div className={classes.blockProfitHeader}>
                                <div className={classes.blockProfitHeaderText+' '+(days===1 && classes.blockProfitHeaderText2)} onClick={()=>setDays(1)}>{text[lang].calc.text11}</div>
                                <div className={classes.blockProfitHeaderText+' '+(days===7 && classes.blockProfitHeaderText2)} onClick={()=>setDays(7)}>{text[lang].calc.text12}</div>
                                <div className={classes.blockProfitHeaderText+' '+(days===30.5 && classes.blockProfitHeaderText2)} onClick={()=>setDays(30.5)}>{text[lang].calc.text13}</div>
                            </div>
                            <div className={classes.blockProfitInfoBlock}>
                                <div className={classes.blockProfitInfo}>
                                    <div className={classes.blockProfitText1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.00002 1.33337C3.93066 1.33337 2.93335 1.48989 2.17947 1.76404C2.15608 1.77254 2.13279 1.78121 2.1096 1.79004C1.76186 1.92254 1.43874 2.09233 1.18688 2.30886C0.920401 2.53796 0.666687 2.88188 0.666687 3.33337V4.33337C0.666687 4.78487 0.920401 5.12879 1.18688 5.35789C1.45553 5.58885 1.80526 5.76664 2.17947 5.90271C2.93335 6.17685 3.93066 6.33337 5.00002 6.33337C6.06938 6.33337 7.06669 6.17685 7.82057 5.90271C8.19478 5.76664 8.54451 5.58885 8.81316 5.35789C9.07964 5.12879 9.33335 4.78487 9.33335 4.33337V3.33337C9.33335 2.88188 9.07964 2.53796 8.81316 2.30886C8.54451 2.07789 8.19478 1.90011 7.82057 1.76404C7.06669 1.48989 6.06938 1.33337 5.00002 1.33337Z" fill="#E35443"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M9.33335 5.46828C8.90964 5.54052 8.51975 5.6403 8.17947 5.76404C7.80526 5.90011 7.45553 6.07789 7.18688 6.30886C6.9204 6.53796 6.66669 6.88188 6.66669 7.33337C6.66669 7.40101 6.67238 7.46623 6.68296 7.52901C6.16359 7.6186 5.59288 7.66671 5.00002 7.66671C3.93066 7.66671 2.93335 7.51019 2.17947 7.23605C1.79225 7.09524 1.43566 6.91409 1.15892 6.66671C1.05509 6.5739 0.971377 6.47846 0.904071 6.38789C0.87903 6.3542 0.839987 6.33337 0.798007 6.33337C0.7261 6.33337 0.668443 6.39276 0.669756 6.46466C0.677068 6.86498 0.666687 7.26657 0.666687 7.66671C0.666687 8.1182 0.920401 8.46212 1.18688 8.69122C1.45553 8.92219 1.80526 9.09997 2.17947 9.23605C2.93335 9.51019 3.93066 9.66671 5.00002 9.66671C5.56989 9.66671 6.1193 9.62226 6.62231 9.53924C6.63799 9.53666 6.64583 9.53536 6.65178 9.53141C6.65701 9.52795 6.66119 9.52302 6.66376 9.51729C6.66669 9.51078 6.66669 9.50278 6.66669 9.48679V9.23445C6.66669 9.19704 6.69702 9.16671 6.73443 9.16671C6.76085 9.16671 6.78471 9.18213 6.79659 9.20572C6.89542 9.402 7.03919 9.56425 7.18688 9.69123C7.45553 9.92219 7.80526 10.1 8.17947 10.236C8.93335 10.5102 9.93066 10.6667 11 10.6667C12.0694 10.6667 13.0667 10.5102 13.8206 10.236C14.1948 10.1 14.5445 9.92219 14.8132 9.69123C15.0796 9.46212 15.3334 9.1182 15.3334 8.66671V7.33337C15.3334 6.88188 15.0796 6.53796 14.8132 6.30886C14.5445 6.07789 14.1948 5.90011 13.8206 5.76404C13.0667 5.48989 12.0694 5.33337 11 5.33337C10.4133 5.33337 9.84836 5.38049 9.33335 5.46828Z" fill="#E35443"/>
                                            <path d="M15.2018 10.6667C15.1571 10.6667 15.1159 10.6896 15.0898 10.7258C15.0073 10.8407 14.9111 10.9404 14.8132 11.0246C14.5445 11.2555 14.1948 11.4333 13.8206 11.5694C13.0667 11.8435 12.0694 12 11 12C9.93066 12 8.93335 11.8435 8.17947 11.5694C7.80526 11.4333 7.45553 11.2555 7.18688 11.0246C7.08896 10.9404 6.99276 10.8407 6.91024 10.7258C6.88419 10.6896 6.84293 10.6667 6.79829 10.6667C6.72561 10.6667 6.66669 10.7256 6.66669 10.7983V10.8651C6.15168 10.9529 5.58669 11 5.00002 11C3.93066 11 2.93335 10.8435 2.17947 10.5694C1.80526 10.4333 1.45553 10.2555 1.18688 10.0246C1.08896 9.94037 0.992759 9.84068 0.910239 9.72584C0.884189 9.68959 0.842927 9.66671 0.798286 9.66671C0.725606 9.66671 0.666687 9.72563 0.666687 9.79831V11.3334C0.666687 11.7849 0.920401 12.1288 1.18688 12.3579C1.45553 12.5889 1.80526 12.7666 2.17947 12.9027C2.93335 13.1769 3.93066 13.3334 5.00002 13.3334C5.63289 13.3334 6.23868 13.2786 6.78295 13.1778C6.8819 13.3867 7.03224 13.5583 7.18688 13.6912C7.45553 13.9222 7.80526 14.1 8.17947 14.236C8.93335 14.5102 9.93066 14.6667 11 14.6667C12.0694 14.6667 13.0667 14.5102 13.8206 14.236C14.1948 14.1 14.5445 13.9222 14.8132 13.6912C15.0796 13.4621 15.3334 13.1182 15.3334 12.6667V10.7983C15.3334 10.7256 15.2744 10.6667 15.2018 10.6667Z" fill="#E35443"/>
                                        </svg>
                                        {text[lang].calc.text14}
                                    </div>
                                    <div>
                                        <div className={classes.blockProfitText2}>{(profit * days).toFixed(8)} BTC</div>
                                        <div className={classes.blockProfitText3}>{toRub((profit * days * courseBTC).toFixed(2))} {lang === 'ru' ? <>RUB</> : <>USD</>}</div>
                                    </div>
                                </div>
                                <div className={classes.blockProfitInfo}>
                                    <div className={classes.blockProfitInfoEnergy}>
                                        <div className={classes.blockProfitText1_1}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M9.32818 1.41602C9.36488 1.12235 9.2036 0.839641 8.93212 0.721796C8.66064 0.60395 8.34397 0.679184 8.15451 0.90654L2.20153 8.05011C2.09866 8.17351 1.99445 8.29851 1.91859 8.40835C1.84684 8.51223 1.71583 8.71648 1.71178 8.98492C1.70715 9.29223 1.84409 9.58461 2.08314 9.77778C2.29195 9.94652 2.53273 9.97664 2.65847 9.98802C2.79141 10.0001 2.95414 10 3.1148 10L7.2448 10L6.6718 14.584C6.6351 14.8776 6.79638 15.1604 7.06786 15.2782C7.33934 15.396 7.65601 15.3208 7.84547 15.0935L13.7984 7.94989C13.9013 7.82648 14.0055 7.70149 14.0814 7.59164C14.1531 7.48776 14.2841 7.28351 14.2882 7.01507C14.2928 6.70776 14.1559 6.41539 13.9168 6.22221C13.708 6.05347 13.4673 6.02336 13.3415 6.01197C13.2086 5.99993 13.0458 5.99996 12.8852 5.99999L8.75518 6L9.32818 1.41602Z" fill="#377DD6"/>
                                            </svg>
                                            {text[lang].calc.text15}
                                        </div>
                                        <div className={classes.blockProfitText3} style={{marginLeft:'24px'}}>
                                            {valueEl} {text[lang].calc.text3}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.blockProfitText2}>{(energyFee * days / courseBTC).toFixed(8)} BTC</div>
                                        <div className={classes.blockProfitText3}>{toRub((energyFee * days).toFixed(2))} {lang === 'ru' ? <>RUB</> : <>USD</>}</div>
                                    </div>
                                </div>
                                <div className={classes.blockProfitInfo}>
                                    <div className={classes.blockProfitText1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M11 1.33329C9.34317 1.33329 8.00002 2.67644 8.00002 4.33329C8.00002 5.99015 9.34317 7.33329 11 7.33329C12.6569 7.33329 14 5.99015 14 4.33329C14 2.67644 12.6569 1.33329 11 1.33329Z" fill="#57976A"/>
                                            <path d="M8.02122 1.18613C7.18705 1.97595 6.66669 3.09385 6.66669 4.33329C6.66669 5.13135 6.88242 5.87903 7.25876 6.52118C6.96729 6.6156 6.65628 6.66663 6.33335 6.66663C4.6765 6.66663 3.33335 5.32348 3.33335 3.66663C3.33335 2.00977 4.6765 0.666626 6.33335 0.666626C6.95924 0.666626 7.54036 0.858292 8.02122 1.18613Z" fill="#57976A"/>
                                            <path d="M11.806 10.5955C11.806 11.123 11.457 11.5692 10.976 11.7183L11.2853 11.704C11.6233 11.704 11.9519 11.5977 12.222 11.4003L14.1079 10.0243C14.5786 9.68112 15.2375 9.72668 15.6535 10.1314C16.1156 10.5809 16.1156 11.3091 15.6535 11.7579L14.0353 13.332C13.5998 13.7557 13.044 14.045 12.4398 14.1627L10.1527 14.6077C9.69914 14.6958 9.23156 14.6851 8.78272 14.5758L6.71415 14.0731C6.4667 14.0124 6.21301 13.982 5.95775 13.982C5.68675 13.982 5.55126 13.982 5.44254 13.9432C5.25424 13.876 5.10606 13.7278 5.03883 13.5395C5.00002 13.4308 5.00002 13.2953 5.00002 13.0243V10.7382C5.00002 10.5476 5.00002 10.4523 5.02449 10.3645C5.04616 10.2868 5.08179 10.2136 5.12962 10.1485C5.18363 10.0751 5.2586 10.0163 5.40856 9.89874L6.19896 9.27905C6.19896 9.27905 7.50879 8.66662 8.2324 8.66662C8.53215 8.66662 8.83034 8.7031 9.1215 8.77529L10.9106 9.22006C11.4367 9.35046 11.806 9.82084 11.806 10.3603V10.5955Z" fill="#57976A"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M2.31571 8.66662C2.32159 8.66662 2.32747 8.66662 2.33336 8.66662C2.33925 8.66662 2.34513 8.66662 2.35101 8.66662C2.49104 8.6666 2.62745 8.66659 2.74351 8.67451C2.87042 8.68317 3.02091 8.70348 3.17693 8.76811C3.50364 8.90344 3.7632 9.163 3.89853 9.48971C3.96316 9.64574 3.98347 9.79622 3.99213 9.92313C4.00005 10.0392 4.00004 10.1756 4.00002 10.3156C4.00002 10.3215 4.00002 10.3274 4.00002 10.3333L4.00002 13.6843C4.00004 13.8243 4.00005 13.9607 3.99213 14.0768C3.98347 14.2037 3.96316 14.3542 3.89853 14.5102C3.7632 14.8369 3.50364 15.0965 3.17693 15.2318C3.0209 15.2964 2.87042 15.3167 2.74351 15.3254C2.62745 15.3333 2.49104 15.3333 2.35101 15.3333H2.3157C2.17567 15.3333 2.03926 15.3333 1.9232 15.3254C1.79629 15.3167 1.64581 15.2964 1.48978 15.2318C1.16307 15.0965 0.903508 14.8369 0.768183 14.5102C0.703554 14.3542 0.683238 14.2037 0.674579 14.0768C0.666661 13.9607 0.666674 13.8243 0.666688 13.6843L0.666689 10.3333C0.666689 10.3274 0.666689 10.3215 0.666688 10.3156C0.666674 10.1756 0.666661 10.0392 0.674579 9.92313C0.683238 9.79622 0.703554 9.64574 0.768183 9.48971C0.903509 9.163 1.16307 8.90344 1.48978 8.76811C1.64581 8.70348 1.79629 8.68317 1.92321 8.67451C2.03926 8.66659 2.17568 8.6666 2.31571 8.66662Z" fill="#57976A"/>
                                        </svg>
                                        {text[lang].calc.text16}
                                    </div>
                                    <div>
                                        <div className={classes.blockProfitText2}>{(profit * days - energyFee * days / courseBTC).toFixed(8)} BTC</div>
                                        <div className={classes.blockProfitText3}>{toRub(((profit * days - energyFee * days / courseBTC) * courseBTC).toFixed(2))} {lang === 'ru' ? <>RUB</> : <>USD</>}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.blockProfitInfoBlock}>
                                <div className={classes.blockProfitInfo}>
                                    <div className={classes.blockProfitText1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <g clipPath="url(#clip0_1438_3111)">
                                                <path d="M15.7589 9.93524C14.6904 14.221 10.3496 16.8292 6.06335 15.7605C1.77889 14.692 -0.829339 10.351 0.239588 6.06558C1.30758 1.77935 5.64835 -0.82907 9.93329 0.239387C14.2192 1.30784 16.8273 5.64936 15.7587 9.93532L15.7588 9.93524H15.7589Z" fill="#F7931A"/>
                                                <path d="M11.5268 6.86022C11.686 5.7956 10.8755 5.22332 9.76712 4.84156L10.1267 3.39943L9.24881 3.18068L8.8988 4.58484C8.66801 4.52728 8.431 4.47305 8.19545 4.41928L8.548 3.00585L7.67068 2.78711L7.31093 4.22877C7.11995 4.18529 6.93237 4.14231 6.75038 4.09703L6.7514 4.09249L5.5408 3.79019L5.30728 4.7278C5.30728 4.7278 5.95858 4.8771 5.94486 4.88629C6.30034 4.97501 6.36463 5.21034 6.35396 5.39686L5.94439 7.03978C5.96887 7.046 6.00062 7.055 6.03565 7.06907C6.00637 7.0618 5.9752 7.05386 5.94286 7.04612L5.36879 9.34762C5.32534 9.45562 5.21507 9.61771 4.96653 9.55616C4.97533 9.56891 4.32848 9.39693 4.32848 9.39693L3.89264 10.4018L5.03504 10.6866C5.24757 10.7399 5.45583 10.7957 5.66093 10.8481L5.29766 12.3068L6.17451 12.5255L6.53426 11.0823C6.77381 11.1473 7.00628 11.2073 7.23386 11.2639L6.87532 12.7003L7.75323 12.919L8.11646 11.4631C9.61341 11.7464 10.739 11.6322 11.2128 10.2782C11.5945 9.18804 11.1938 8.55926 10.4062 8.14922C10.9798 8.01694 11.4119 7.63967 11.5271 6.86034L11.5268 6.86014L11.5268 6.86022ZM9.52105 9.67276C9.24975 10.7629 7.41432 10.1736 6.81925 10.0258L7.30131 8.09334C7.89635 8.2419 9.80458 8.53588 9.52109 9.67276H9.52105ZM9.79254 6.84442C9.54506 7.83601 8.01741 7.33224 7.52185 7.20871L7.95891 5.45606C8.45447 5.57959 10.0503 5.81014 9.79262 6.84442H9.79254Z" fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1438_3111">
                                                    <rect width="15.9982" height="16" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        {text[lang].calc.text20}
                                    </div>
                                    <div>
                                        <div className={classes.blockProfitText2}>{toRub((courseBTCRub / courseUSD).toFixed(2))} USD</div>
                                        {lang === 'ru' && <div className={classes.blockProfitText3}>{toRub(courseBTCRub.toFixed(2))} RUB</div>}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.blockProfitSendEdit}>
                                <div className={classes.blockProfitSend} onClick={()=>{setStep(0); setTextButton(text[lang].calc.text25)}}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.33331 2.66617H4.53331C3.41321 2.66617 2.85316 2.66617 2.42533 2.88415C2.04901 3.0759 1.74305 3.38186 1.5513 3.75819C1.33331 4.18601 1.33331 4.74606 1.33331 5.86617V11.4662C1.33331 12.5863 1.33331 13.1463 1.5513 13.5741C1.74305 13.9505 2.04901 14.2564 2.42533 14.4482C2.85316 14.6662 3.41321 14.6662 4.53331 14.6662H10.1333C11.2534 14.6662 11.8135 14.6662 12.2413 14.4482C12.6176 14.2564 12.9236 13.9505 13.1153 13.5741C13.3333 13.1463 13.3333 12.5863 13.3333 11.4662V8.66617M5.3333 10.6662H6.44966C6.77578 10.6662 6.93884 10.6662 7.09229 10.6293C7.22834 10.5967 7.3584 10.5428 7.47769 10.4697C7.61225 10.3872 7.72755 10.2719 7.95815 10.0413L14.3333 3.66617C14.8856 3.11388 14.8856 2.21845 14.3333 1.66617C13.781 1.11388 12.8856 1.11388 12.3333 1.66617L5.95814 8.04133C5.72753 8.27193 5.61223 8.38723 5.52978 8.52179C5.45667 8.64108 5.4028 8.77114 5.37014 8.90719C5.3333 9.06064 5.3333 9.2237 5.3333 9.54982V10.6662Z" stroke="var(--tg-theme-link-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    {text[lang].calc.text24}
                                </div>
                                <div className={classes.blockProfitSend} onClick={()=>setOpenSendProfit(true)}>
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.3609 8.40513C14.5237 8.26563 14.605 8.19589 14.6348 8.11289C14.661 8.04004 14.661 7.96035 14.6348 7.8875C14.605 7.8045 14.5237 7.73476 14.3609 7.59526L8.71375 2.75484C8.4336 2.51471 8.29353 2.39465 8.17493 2.3917C8.07186 2.38915 7.97341 2.43443 7.90828 2.51435C7.83333 2.6063 7.83333 2.7908 7.83333 3.15978V6.02328C6.41021 6.27225 5.10773 6.99337 4.13981 8.07611C3.08455 9.25654 2.50082 10.7842 2.5 12.3675V12.7755C3.19956 11.9328 4.073 11.2512 5.06051 10.7775C5.93113 10.3598 6.87228 10.1124 7.83333 10.0472V12.8406C7.83333 13.2096 7.83333 13.3941 7.90828 13.486C7.97341 13.566 8.07186 13.6112 8.17493 13.6087C8.29353 13.6057 8.4336 13.4857 8.71375 13.2455L14.3609 8.40513Z" stroke="var(--tg-theme-link-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    {text[lang].calc.text21}
                                </div>
                            </div>
                        </div>
                        <div className={classes.blockModels+' '+classes.blockModels2}>
                            {allItems.map((item,number) =>
                                <div key={item.item.id} className={classes.block+' '+(number!==0 && classes.block_1)} style={{cursor:'initial'}}>
                                    <div className={classes.imageModelsBlock}>
                                        <img alt='' className={classes.imageModels} src={server+item.item.img}/>
                                    </div>
                                    <div className={classes.block_text}>{item.item.firma} {item.item.name} {item.item.hashrate}</div>
                                    <div className={classes.block_count}>{item.count} {text[lang].calc.text17}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.footer} onClick={goShop}>
                        {text[lang].calc.text18}
                    </div>
                </div>
            </CSSTransition>
            {step === 6 &&
                <div className={classes.popup2}>
                    <div className={classes.mainBlock2}>
                        <div className={classes.text8}>{text[lang].calc.text19}</div>
                    </div>
                    <div className={classes.footer} onClick={()=>{setStep(0); navigate('/shop')}}>
                        {text[lang].calc.text18}
                    </div>
                </div>
            }
            <Loading isOpen={loading}/>
            <CSSTransition in={openSendProfit}
                           timeout={300}
                           classNames="block"
                           unmountOnExit
            >
                <div className={classes.popupSendFon} onClick={(e)=>{setOpenSendProfit(false)}}>
                    <div className={classes.popupSend} onClick={(e)=>{e.stopPropagation()}}>
                        <div className={classes.popupSendText}>{text[lang].calc.text21}</div>
                        <div className={classes.popupSendInput}>
                            <input className={classes.popupSendInputText} value={textSend} disabled={true}/>
                            <div className={classes.popupSendCopy} onClick={copyLink}>
                                {copyLinkText ?
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                            <path d="M13.8333 4L6.49996 11.3333L3.16663 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <div className={classes.popupSendCopyText}>{text[lang].calc.text23}</div>
                                    </>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M5 2H9.73333C11.2268 2 11.9735 2 12.544 2.29065C13.0457 2.54631 13.4537 2.95426 13.7094 3.45603C14 4.02646 14 4.77319 14 6.26667V11M4.13333 14H9.53333C10.2801 14 10.6534 14 10.9387 13.8547C11.1895 13.7268 11.3935 13.5229 11.5213 13.272C11.6667 12.9868 11.6667 12.6134 11.6667 11.8667V6.46667C11.6667 5.71993 11.6667 5.34656 11.5213 5.06135C11.3935 4.81046 11.1895 4.60649 10.9387 4.47866C10.6534 4.33333 10.2801 4.33333 9.53333 4.33333H4.13333C3.3866 4.33333 3.01323 4.33333 2.72801 4.47866C2.47713 4.60649 2.27316 4.81046 2.14532 5.06135C2 5.34656 2 5.71993 2 6.46667V11.8667C2 12.6134 2 12.9868 2.14532 13.272C2.27316 13.5229 2.47713 13.7268 2.72801 13.8547C3.01323 14 3.3866 14 4.13333 14Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                }
                            </div>
                        </div>
                        <div className={classes.blockProfitSend2} onClick={sendCalc}>
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.3609 8.40513C14.5237 8.26563 14.605 8.19589 14.6348 8.11289C14.661 8.04004 14.661 7.96035 14.6348 7.8875C14.605 7.8045 14.5237 7.73476 14.3609 7.59526L8.71375 2.75484C8.4336 2.51471 8.29353 2.39465 8.17493 2.3917C8.07186 2.38915 7.97341 2.43443 7.90828 2.51435C7.83333 2.6063 7.83333 2.7908 7.83333 3.15978V6.02328C6.41021 6.27225 5.10773 6.99337 4.13981 8.07611C3.08455 9.25654 2.50082 10.7842 2.5 12.3675V12.7755C3.19956 11.9328 4.073 11.2512 5.06051 10.7775C5.93113 10.3598 6.87228 10.1124 7.83333 10.0472V12.8406C7.83333 13.2096 7.83333 13.3941 7.90828 13.486C7.97341 13.566 8.07186 13.6112 8.17493 13.6087C8.29353 13.6057 8.4336 13.4857 8.71375 13.2455L14.3609 8.40513Z" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            {text[lang].calc.text21}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </TelegramScreen>
    );
};

export default Calc;