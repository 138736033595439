import React, {useEffect, useRef, useState} from 'react';
import classes from "./search.module.css";
import TelegramScreen from "../../common/telegramScreen/telegramScreen";
import {useTelegram} from "../../../hooks/useTelegram";
import {text} from "../../translate";
import {CSSTransition} from "react-transition-group";
import image from '../../../image/png/img.png'
import {toRub} from "../../../utils/money";
import {get_search, server} from "../../../utils/api";


const Search = ({step, setStep, startParams, setStartParams}) => {
    const {lang, webApp} = useTelegram()
    const ref = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const [openFreeEl, setOpenFreeEl] = useState(false)
    const [valueEl, setValueEl] = useState('')
    const [valueBudget, setValueBudget] = useState('')
    const [valueEnergy, setValueEnergy] = useState('')
    const [asics, setAsics] = useState([
        {id:0, name:'M50', firma:'Whats', hash:'110th', watt:'3600', count:4, priceRub: 120000, price: 1200, image: image},
        {id:1, name:'M50', firma:'Whats', hash:'110th', watt:'3600', count:1, priceRub: 120000, price: 1200, image: image}
    ])
    const [profit, setProfit] = useState({
        rub: 123,
        btc: 0.321321,
        usdt: 2
    })
    const [openSendProfit, setOpenSendProfit] = useState(false)
    const [copyLinkText, setCopyLinkText] = useState(false)
    const [textSend, setTextSend] = useState('')
    const [openTopPopup, setOpenTopPopup] = useState(false)

    useEffect( ()=> {
        const getStartParams = async (c) => {
        console.log(startParams)
        if(startParams && startParams.budget){
            setStep(1)
            const res = await get_search(
                startParams.budget,
                startParams.el.substring(1).replace('T','.'),
                startParams.el[0],
                startParams.energy
            )
            if(res.result.length === 0){
                setStep(2)
            }else{
                setAsics(res.result)
                setProfit(res.total_net_profit)
                setTimeout(()=>setStep(3), 2000)
            }
        }
        setStartParams({})
        }
        getStartParams()
    },[])

    async function startSearch(){
        if(valueBudget > 0){
            if((lang==='ru' && valueBudget >= 100000000) || (lang!=='ru' && valueBudget >= 1000000)){
                setOpenTopPopup(true)
                return
            }
            setStep(1)
            let en = valueEl.replace(',','T')
            setTextSend('https://t.me/ImineShopBot/ImineShop?startapp=search='+(lang==='ru'?'R':'D')+(en===''?0:en)+'='+valueBudget+'='+(valueEnergy===''?0:valueEnergy))
            //setTimeout(()=>setStep(3), 3000)
            const res = await get_search(valueBudget, valueEl.replace(',','.'), lang==='ru'?'R':'D', valueEnergy)
            if(res.result.length === 0){
                setStep(2)
            }else{
                setAsics(res.result)
                setProfit(res.total_net_profit)
                setTimeout(()=>setStep(3), 2000)
            }
        }
    }

    function goStart(){
        setStep(0)
    }

    function sendCalc(){
        let textAreaValue = "Посмотри, какая прибыль:"
        window.open(
            `https://t.me/share/url?url=${textSend}&text=${textAreaValue}`
        );
        setOpenSendProfit(false)
    }

    function copyLink(){
        setCopyLinkText(true)
        navigator.clipboard.writeText(textSend);
        setTimeout(()=>setCopyLinkText(false), 3000)
    }

    return (
        <TelegramScreen showbackbutton={true}>
            <div className={classes.mainBlock}>
                <div className={classes.text1}>{text[lang].search.text3}</div>
                <div className={classes.blockEl}>
                    <div className={classes.text2}>{text[lang].search.text4}</div>
                    <div className={classes.blockPress} onClick={() => ref.current.focus()}></div>
                    <div className={classes.blockEl2}>
                        <input type={"text"}
                               ref={ref}
                               className={classes.inputEl}
                               value={valueBudget}
                               pattern="\\d*"
                               inputMode="decimal"
                               placeholder={'0'}
                               onChange={(e) => {
                                   if (e.target.value.length === 0) {
                                       setValueBudget('')
                                   } else {
                                       if (e.target.value.length < 10) {
                                           let last = e.target.value[e.target.value.length - 1]
                                           const alf = '1234567890,.'
                                           if (e.target.value === '.' || e.target.value === ',') {
                                               setValueBudget('0,')
                                           } else {
                                               if (alf.indexOf(last) !== -1) {
                                                   if ((last === '.' || last === ',') && (e.target.value.slice(0, -1).indexOf('.') !== -1 || e.target.value.slice(0, -1).indexOf(',') !== -1)) {

                                                   } else {
                                                       if (e.target.value.indexOf(',') !== -1 || e.target.value.indexOf('.') !== -1)
                                                           ref.current.style.width = e.target.value.length * 20 + 'px';
                                                       else
                                                           ref.current.style.width = e.target.value.length * 20 + 10 + 'px';
                                                       setValueBudget(e.target.value)
                                                   }
                                               }
                                           }
                                       }
                                   }

                               }}

                        />
                        <div className={classes.text3}>{text[lang].calc.text3.split('/')[0]}</div>
                    </div>
                </div>
                <div className={classes.blockEl}>
                    <div className={classes.text2}>{text[lang].calc.text2}</div>
                    <div className={classes.blockPress} onClick={() => ref2.current.focus()}></div>
                    <div className={classes.blockEl2}>
                        <input type={"text"}
                               ref={ref2}
                               className={classes.inputEl}
                               value={valueEl}
                               pattern="\\d*"
                               inputMode="decimal"
                               placeholder={'0'}
                               onChange={(e) => {
                                   if (e.target.value.length === 0) {
                                       setValueEl('')
                                   } else {
                                       if (e.target.value.length < 6) {
                                           let last = e.target.value[e.target.value.length - 1]
                                           const alf = '1234567890,.'
                                           if (e.target.value === '.' || e.target.value === ',') {
                                               setValueEl('0,')
                                           } else {
                                               if (alf.indexOf(last) !== -1) {
                                                   if ((last === '.' || last === ',') && (e.target.value.slice(0, -1).indexOf('.') !== -1 || e.target.value.slice(0, -1).indexOf(',') !== -1)) {

                                                   } else {
                                                       if (e.target.value.indexOf(',') !== -1 || e.target.value.indexOf('.') !== -1)
                                                           ref2.current.style.width = e.target.value.length * 20 + 'px';
                                                       else
                                                           ref2.current.style.width = e.target.value.length * 20 + 10 + 'px';
                                                       setValueEl(e.target.value)
                                                   }
                                               }
                                           }
                                       }
                                   }

                               }}
                        />
                        <div className={classes.text3}>{text[lang].calc.text3}</div>
                    </div>
                </div>
                <div>
                    <div className={classes.emptyBlock}></div>
                    <div className={classes.blockFreeEl}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>{setOpenFreeEl(!openFreeEl); setValueEnergy('')}}>
                            <rect x="1" y="1" width="18" height="18" rx="3" stroke="#5EA5DE" strokeWidth="2"/>
                            {openFreeEl && <path d="M15.3334 6L8.00008 13.3333L4.66675 10" stroke="#5EA5DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>}
                        </svg>
                        <div className={classes.text2}>{text[lang].search.text19}</div>
                    </div>
                </div>
                {openFreeEl && <div className={classes.blockEl}>
                    <div className={classes.text2}>{text[lang].search.text18}</div>
                    <div className={classes.blockPress} onClick={() => ref3.current.focus()}></div>
                    <div className={classes.blockEl2}>
                        <input type={"text"}
                               ref={ref3}
                               className={classes.inputEl}
                               value={valueEnergy}
                               pattern="\\d*"
                               inputMode="decimal"
                               placeholder={'0'}
                               onChange={(e) => {
                                   if (e.target.value.length === 0) {
                                       setValueEnergy('')
                                   } else {
                                       if (e.target.value.length < 10) {
                                           let last = e.target.value[e.target.value.length - 1]
                                           const alf = '1234567890,.'
                                           if (e.target.value === '.' || e.target.value === ',') {
                                               setValueEnergy('0,')
                                           } else {
                                               if (alf.indexOf(last) !== -1) {
                                                   if ((last === '.' || last === ',') && (e.target.value.slice(0, -1).indexOf('.') !== -1 || e.target.value.slice(0, -1).indexOf(',') !== -1)) {

                                                   } else {
                                                       if (e.target.value.indexOf(',') !== -1 || e.target.value.indexOf('.') !== -1)
                                                           ref3.current.style.width = e.target.value.length * 20 + 'px';
                                                       else
                                                           ref3.current.style.width = e.target.value.length * 20 + 10 + 'px';
                                                       setValueEnergy(e.target.value)
                                                   }
                                               }
                                           }
                                       }
                                   }

                               }}
                        />
                        <div className={classes.text3}>{text[lang].search.text20}</div>
                    </div>
                </div>
                }
            </div>
            <div className={valueBudget > 0? classes.footer : classes.footer2} onClick={startSearch}>
                {text[lang].search.text5}
            </div>
            <CSSTransition in={step === 1}
                           timeout={300}
                           classNames="block"
                           unmountOnExit
            >
                <div className={classes.loading}>
                    <div className={classes.loadingIco}>
                        <svg className={classes.loadingSVG} xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                            <path d="M17.1666 10.0007C17.1666 9.08018 17.9128 8.33398 18.8333 8.33398C24.3562 8.33398 28.8333 12.8111 28.8333 18.334C28.8333 19.2545 28.0871 20.0007 27.1666 20.0007C26.2462 20.0007 25.5 19.2545 25.5 18.334C25.5 14.6521 22.5152 11.6673 18.8333 11.6673C17.9128 11.6673 17.1666 10.9211 17.1666 10.0007Z" fill="#4497F6"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M3.83331 18.334C3.83331 10.0497 10.549 3.33398 18.8333 3.33398C27.1176 3.33398 33.8333 10.0497 33.8333 18.334C33.8333 21.8757 32.6059 25.1307 30.5531 27.6968L36.6785 33.8221C37.3294 34.473 37.3294 35.5283 36.6785 36.1792C36.0276 36.83 34.9723 36.83 34.3215 36.1792L28.1961 30.0538C25.63 32.1065 22.375 33.334 18.8333 33.334C10.549 33.334 3.83331 26.6183 3.83331 18.334ZM18.8333 6.66732C12.39 6.66732 7.16665 11.8907 7.16665 18.334C7.16665 24.7773 12.39 30.0007 18.8333 30.0007C25.2766 30.0007 30.5 24.7773 30.5 18.334C30.5 11.8907 25.2766 6.66732 18.8333 6.66732Z" fill="#4497F6"/>
                        </svg>
                    </div>
                    <div className={classes.loadingText1}>{text[lang].search.text6}</div>
                    <div className={classes.loadingText2}>{text[lang].search.text7}</div>
                </div>
            </CSSTransition>
            <CSSTransition in={step === 2}
                           timeout={300}
                           classNames="block"
                           unmountOnExit
            >
                <div className={classes.loading}>
                    <div className={classes.loadingBlock}>
                        <div className={classes.loadingIco2}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M20.5 1.66602C10.3748 1.66602 2.16666 9.87413 2.16666 19.9993C2.16666 30.1246 10.3748 38.3327 20.5 38.3327C30.6252 38.3327 38.8333 30.1246 38.8333 19.9993C38.8333 9.87413 30.6252 1.66602 20.5 1.66602ZM26.6785 13.8208C27.3294 14.4717 27.3294 15.527 26.6785 16.1779L22.857 19.9993L26.6785 23.8208C27.3294 24.4717 27.3294 25.527 26.6785 26.1779C26.0276 26.8287 24.9723 26.8287 24.3215 26.1779L20.5 22.3564L16.6785 26.1779C16.0276 26.8287 14.9724 26.8287 14.3215 26.1779C13.6706 25.527 13.6706 24.4717 14.3215 23.8208L18.143 19.9993L14.3215 16.1779C13.6706 15.527 13.6706 14.4717 14.3215 13.8208C14.9724 13.17 16.0276 13.17 16.6785 13.8208L20.5 17.6423L24.3215 13.8208C24.9723 13.17 26.0276 13.17 26.6785 13.8208Z" fill="#EF4444"/>
                            </svg>
                        </div>
                        <div className={classes.loadingText1}>{text[lang].search.text8}</div>
                        <div className={classes.loadingText2}>{text[lang].search.text9}</div>
                    </div>
                    <div className={classes.footer} onClick={goStart}>
                        {text[lang].search.text10}
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={step === 3}
                           timeout={300}
                           classNames="block"
                           unmountOnExit
            >
                <div className={classes.loading}>
                    <div className={classes.mainBlock} style={{width:'100%'}}>
                        <div className={classes.text1}>{text[lang].search.text11}</div>
                        <div className={classes.asics}>
                            {asics.map(item=>
                                <div key={item.id} className={classes.asicBlock}>
                                    <div className={classes.asicImageBlock}>
                                        <img alt={''} className={classes.asicImage} src={server+item.image}/>
                                    </div>
                                    <div>
                                        {lang === 'en' ? <div className={classes.price}>{(item.price * item.count).toFixed(0)} USDT</div> :
                                            <>
                                                <div className={classes.price}>{toRub((item.priceRub * item.count).toFixed(0))} руб</div>
                                                <div className={classes.price2}>{(item.price * item.count).toFixed(0)} USDT </div>
                                            </>
                                        }
                                        <div className={classes.name}>
                                            {item.firma+' '+item.name}
                                            <span className={classes.price2}>x{item.count}</span>
                                        </div>
                                        <div className={classes.hashName}>
                                            {text[lang].cart.text2}
                                            <span className={classes.hash}>{item.hash}</span>
                                        </div>
                                        <div className={classes.hashName}>
                                            {text[lang].search.text15}
                                            <span className={classes.hash}>{item.watt}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className={classes.allPriceBlock}>
                                <div className={classes.allPriceText1}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.25 1.66602C4.91331 1.66602 3.66667 1.86167 2.72431 2.20434C2.69508 2.21497 2.66596 2.22581 2.63698 2.23685C2.20231 2.40247 1.7984 2.61471 1.48358 2.88537C1.15048 3.17175 0.833336 3.60164 0.833336 4.16602V5.41602C0.833336 5.98039 1.15048 6.41028 1.48358 6.69666C1.81939 6.98537 2.25656 7.2076 2.72431 7.37769C3.66667 7.72037 4.91331 7.91602 6.25 7.91602C7.5867 7.91602 8.83333 7.72037 9.77569 7.37769C10.2434 7.2076 10.6806 6.98537 11.0164 6.69666C11.3495 6.41028 11.6667 5.98039 11.6667 5.41602V4.16602C11.6667 3.60164 11.3495 3.17175 11.0164 2.88537C10.6806 2.59666 10.2434 2.37444 9.77569 2.20434C8.83333 1.86167 7.5867 1.66602 6.25 1.66602Z" fill="#74BE60"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.6667 6.83465C11.137 6.92495 10.6497 7.04967 10.2243 7.20434C9.75656 7.37444 9.31939 7.59667 8.98358 7.88537C8.65048 8.17175 8.33333 8.60164 8.33333 9.16602C8.33333 9.25056 8.34046 9.33208 8.35368 9.41056C7.70447 9.52255 6.99108 9.58268 6.25 9.58268C4.91331 9.58268 3.66667 9.38703 2.72431 9.04435C2.24029 8.86835 1.79455 8.64191 1.44862 8.33268C1.31884 8.21667 1.2142 8.09737 1.13007 7.98416C1.09876 7.94204 1.04996 7.91602 0.997486 7.91602C0.907602 7.91602 0.83553 7.99025 0.837172 8.08012C0.846313 8.58052 0.833336 9.08251 0.833336 9.58268C0.833336 10.1471 1.15048 10.577 1.48358 10.8633C1.81939 11.152 2.25656 11.3743 2.72431 11.5444C3.66667 11.887 4.91331 12.0827 6.25 12.0827C6.96234 12.0827 7.6491 12.0271 8.27786 11.9234C8.29746 11.9201 8.30726 11.9185 8.3147 11.9136C8.32124 11.9092 8.32646 11.9031 8.32968 11.8959C8.33334 11.8878 8.33333 11.8778 8.33333 11.8578V11.5424C8.33333 11.4956 8.37125 11.4577 8.41802 11.4577C8.45103 11.4577 8.48087 11.477 8.49571 11.5065C8.61925 11.7518 8.79896 11.9546 8.98358 12.1133C9.31939 12.402 9.75656 12.6243 10.2243 12.7944C11.1667 13.137 12.4133 13.3327 13.75 13.3327C15.0867 13.3327 16.3333 13.137 17.2757 12.7944C17.7434 12.6243 18.1806 12.402 18.5164 12.1133C18.8495 11.827 19.1667 11.3971 19.1667 10.8327V9.16602C19.1667 8.60164 18.8495 8.17175 18.5164 7.88537C18.1806 7.59667 17.7434 7.37444 17.2757 7.20434C16.3333 6.86167 15.0867 6.66602 13.75 6.66602C13.0167 6.66602 12.3104 6.7249 11.6667 6.83465Z" fill="#74BE60"/>
                                        <path d="M19.0022 13.3327C18.9464 13.3327 18.8948 13.3613 18.8622 13.4066C18.7591 13.5501 18.6388 13.6748 18.5164 13.78C18.1806 14.0687 17.7434 14.2909 17.2757 14.461C16.3333 14.8037 15.0867 14.9993 13.75 14.9993C12.4133 14.9993 11.1667 14.8037 10.2243 14.461C9.75656 14.2909 9.31939 14.0687 8.98358 13.78C8.86118 13.6748 8.74093 13.5501 8.63778 13.4066C8.60521 13.3613 8.55364 13.3327 8.49783 13.3327C8.40698 13.3327 8.33333 13.4063 8.33333 13.4972V13.5807C7.68958 13.6905 6.98334 13.7493 6.25 13.7493C4.91331 13.7493 3.66667 13.5537 2.72431 13.211C2.25656 13.0409 1.81939 12.8187 1.48358 12.53C1.36118 12.4248 1.24093 12.3001 1.13778 12.1566C1.10521 12.1113 1.05364 12.0827 0.997835 12.0827C0.906985 12.0827 0.833336 12.1563 0.833336 12.2472V14.166C0.833336 14.7304 1.15048 15.1603 1.48358 15.4467C1.81939 15.7354 2.25656 15.9576 2.72431 16.1277C3.66667 16.4704 4.91331 16.666 6.25 16.666C7.0411 16.666 7.79832 16.5975 8.47866 16.4715C8.60236 16.7326 8.79028 16.9471 8.98358 17.1133C9.31939 17.402 9.75656 17.6243 10.2243 17.7944C11.1667 18.137 12.4133 18.3327 13.75 18.3327C15.0867 18.3327 16.3333 18.137 17.2757 17.7944C17.7434 17.6243 18.1806 17.402 18.5164 17.1133C18.8495 16.827 19.1667 16.3971 19.1667 15.8327V13.4972C19.1667 13.4063 19.093 13.3327 19.0022 13.3327Z" fill="#74BE60"/>
                                    </svg>
                                    {text[lang].payment.text3}
                                </div>
                                <div className={classes.allPriceBlock2}>
                                    {lang === 'en' ? <div className={classes.price}>{(asics.reduce((sum, item) => sum + item.price * item.count, 0)).toFixed(0)} USDT</div> :
                                        <>
                                            <div className={classes.price}>{toRub((asics.reduce((sum, item) => sum + item.priceRub * item.count, 0)).toFixed(0))} руб</div>
                                            <div className={classes.price2}>{(asics.reduce((sum, item) => sum + item.price * item.count, 0)).toFixed(0)} USDT </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={classes.asics}>
                            <div className={classes.allPriceBlock}>
                                <div className={classes.allPriceText1}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M6.66667 12.5V14.1667M10 9.16667V14.1667M13.3333 5.83333V14.1667M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z" stroke="#EAB308" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    {text[lang].search.text14}
                                </div>
                            </div>
                            <div className={classes.allPriceBlock+' '+classes.border}>
                                <div className={classes.text4}>
                                    {text[lang].calc.text11}
                                </div>
                                <div className={classes.allPriceBlock2}>
                                    <div className={classes.price}>{(profit.btc).toFixed(7)} BTC</div>
                                    {lang === 'en' ?<div className={classes.price2}>{profit.usdt} USDT</div>:
                                        <div className={classes.price2}>{profit.rub} RUB</div>
                                    }
                                </div>
                            </div>
                            <div className={classes.allPriceBlock+' '+classes.border}>
                                <div className={classes.text4}>
                                    {text[lang].calc.text12}
                                </div>
                                <div className={classes.allPriceBlock2}>
                                    <div className={classes.price}>{(profit.btc*7).toFixed(7)} BTC</div>
                                    {lang === 'en' ?<div className={classes.price2}>{(profit.usdt*7).toFixed(2)} USDT</div>:
                                        <div className={classes.price2}>{(profit.rub*7).toFixed(2)} RUB</div>
                                    }
                                </div>
                            </div>
                            <div className={classes.allPriceBlock+' '+classes.border}>
                                <div className={classes.text4}>
                                    {text[lang].calc.text13}
                                </div>
                                <div className={classes.allPriceBlock2}>
                                    <div className={classes.price}>{(profit.btc*31).toFixed(7)} BTC</div>
                                    {lang === 'en' ?<div className={classes.price2}>{(profit.usdt*31).toFixed(2)} USDT</div>:
                                        <div className={classes.price2}>{(profit.rub*31).toFixed(2)} RUB</div>
                                    }
                                </div>
                            </div>
                            <div className={classes.allPriceBlock}>
                                <div className={classes.allPriceText1}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M18.9166 9.58333L17.2505 11.25L15.5833 9.58333M17.4542 10.8333C17.4845 10.5597 17.5 10.2817 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C12.3561 17.5 14.4584 16.4136 15.8333 14.7144M10 5.83333V10L12.5 11.6667" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    {text[lang].search.text12}
                                </div>
                                <div className={classes.allPriceText1}>≈ {(asics.reduce((sum, item) => sum + item.price * item.count, 0)/(profit.usdt*31)).toFixed(1)} {text[lang].search.text16}</div>
                            </div>
                        </div>
                        <div className={classes.send} style={{marginBottom:'30px'}} onClick={()=>setOpenSendProfit(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                <path d="M17.8261 10.5052C18.0296 10.3308 18.1313 10.2436 18.1686 10.1399C18.2013 10.0488 18.2013 9.94922 18.1686 9.85816C18.1313 9.75441 18.0296 9.66722 17.8261 9.49285L10.7672 3.44233C10.417 3.14217 10.2419 2.99209 10.0937 2.98841C9.96483 2.98521 9.84177 3.04181 9.76035 3.14171C9.66667 3.25666 9.66667 3.48727 9.66667 3.9485V7.52788C7.88777 7.83909 6.25966 8.74049 5.04976 10.0939C3.73069 11.5694 3.00103 13.479 3 15.4582V15.9682C3.87445 14.9147 4.96626 14.0628 6.20063 13.4706C7.28891 12.9485 8.46535 12.6393 9.66667 12.5578V16.0495C9.66667 16.5108 9.66667 16.7414 9.76035 16.8563C9.84177 16.9562 9.96483 17.0128 10.0937 17.0096C10.2419 17.006 10.417 16.8559 10.7672 16.5557L17.8261 10.5052Z" stroke={'var(--tg-theme-link-color)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            {text[lang].search.text13}
                        </div>
                    </div>
                    <div className={classes.footer} style={{background: '#5CB354', color: '#FFFFFF'}}  onClick={startSearch}>
                        {text[lang].calc.text18}
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={openSendProfit}
                           timeout={300}
                           classNames="block"
                           unmountOnExit
            >
                <div className={classes.popupSendFon} onClick={(e)=>{setOpenSendProfit(false)}}>
                    <div className={classes.popupSend} onClick={(e)=>{e.stopPropagation()}}>
                        <div className={classes.popupSendText}>{text[lang].search.text13}</div>
                        <div className={classes.popupSendInput}>
                            <input className={classes.popupSendInputText} value={textSend} disabled={true}/>
                            <div className={classes.popupSendCopy} onClick={copyLink}>
                                {copyLinkText ?
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                            <path d="M13.8333 4L6.49996 11.3333L3.16663 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <div className={classes.popupSendCopyText}>{text[lang].calc.text23}</div>
                                    </>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M5 2H9.73333C11.2268 2 11.9735 2 12.544 2.29065C13.0457 2.54631 13.4537 2.95426 13.7094 3.45603C14 4.02646 14 4.77319 14 6.26667V11M4.13333 14H9.53333C10.2801 14 10.6534 14 10.9387 13.8547C11.1895 13.7268 11.3935 13.5229 11.5213 13.272C11.6667 12.9868 11.6667 12.6134 11.6667 11.8667V6.46667C11.6667 5.71993 11.6667 5.34656 11.5213 5.06135C11.3935 4.81046 11.1895 4.60649 10.9387 4.47866C10.6534 4.33333 10.2801 4.33333 9.53333 4.33333H4.13333C3.3866 4.33333 3.01323 4.33333 2.72801 4.47866C2.47713 4.60649 2.27316 4.81046 2.14532 5.06135C2 5.34656 2 5.71993 2 6.46667V11.8667C2 12.6134 2 12.9868 2.14532 13.272C2.27316 13.5229 2.47713 13.7268 2.72801 13.8547C3.01323 14 3.3866 14 4.13333 14Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                }
                            </div>
                        </div>
                        <div className={classes.blockProfitSend2} onClick={sendCalc}>
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.3609 8.40513C14.5237 8.26563 14.605 8.19589 14.6348 8.11289C14.661 8.04004 14.661 7.96035 14.6348 7.8875C14.605 7.8045 14.5237 7.73476 14.3609 7.59526L8.71375 2.75484C8.4336 2.51471 8.29353 2.39465 8.17493 2.3917C8.07186 2.38915 7.97341 2.43443 7.90828 2.51435C7.83333 2.6063 7.83333 2.7908 7.83333 3.15978V6.02328C6.41021 6.27225 5.10773 6.99337 4.13981 8.07611C3.08455 9.25654 2.50082 10.7842 2.5 12.3675V12.7755C3.19956 11.9328 4.073 11.2512 5.06051 10.7775C5.93113 10.3598 6.87228 10.1124 7.83333 10.0472V12.8406C7.83333 13.2096 7.83333 13.3941 7.90828 13.486C7.97341 13.566 8.07186 13.6112 8.17493 13.6087C8.29353 13.6057 8.4336 13.4857 8.71375 13.2455L14.3609 8.40513Z" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            {text[lang].calc.text22}
                        </div>
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={openTopPopup}
                           timeout={300}
                           classNames="block"
                           unmountOnExit
            >
                <div className={classes.popupSendFon} onClick={(e)=>{setOpenTopPopup(false)}}>
                    <div className={classes.popupSend} onClick={(e)=>{e.stopPropagation()}}>
                        <svg width="71" height="64" viewBox="0 0 71 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.83179 22H67.1651M28.8318 2L22.1651 22L35.4985 60.3333L48.8318 22L42.1651 2M37.547 59.5417L67.4092 23.7072C67.9152 23.0998 68.1683 22.7962 68.265 22.4572C68.3503 22.1584 68.3503 21.8416 68.265 21.5428C68.1683 21.2038 67.9152 20.9002 67.4092 20.2928L52.9647 2.95951C52.6708 2.60681 52.5238 2.43047 52.3437 2.30369C52.1841 2.19136 52.006 2.10794 51.8175 2.05723C51.6048 2 51.3752 2 50.9161 2H20.0808C19.6217 2 19.3921 2 19.1794 2.05723C18.9909 2.10794 18.8128 2.19136 18.6532 2.30369C18.4731 2.43047 18.3261 2.60681 18.0322 2.95951L3.58775 20.2928C3.08166 20.9001 2.82861 21.2038 2.73191 21.5428C2.64665 21.8416 2.64665 22.1584 2.73191 22.4572C2.82861 22.7962 3.08166 23.0998 3.58775 23.7071L33.4499 59.5417C34.1534 60.3859 34.5051 60.808 34.9263 60.962C35.2958 61.097 35.7011 61.097 36.0706 60.962C36.4918 60.808 36.8435 60.3859 37.547 59.5417Z" stroke="url(#paint0_linear_4864_828)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            <defs>
                                <linearGradient id="paint0_linear_4864_828" x1="35.4985" y1="2" x2="35.4985" y2="61.0632" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#C6A771"/>
                                    <stop offset="1" stopColor="#C5A56F"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <div className={classes.popupTopText}>{text[lang].search.text21}</div>
                        <div className={classes.popupTopText2}>{text[lang].search.text22}</div>
                        <div className={classes.popupTopText2}>{text[lang].search.text23}</div>
                        <div className={classes.popupTopBottom} onClick={()=>webApp.openLink('https://t.me/imine_manager')}>
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.0955 6.10679C5.38958 4.27797 8.24822 3.06276 9.68367 2.47321C13.7692 0.800794 14.628 0.512032 15.1801 0.5C15.3028 0.5 15.5727 0.524064 15.7567 0.668445C15.904 0.788762 15.9408 0.945175 15.9653 1.06549C15.9898 1.18581 16.0144 1.43848 15.9898 1.63098C15.769 3.91702 14.812 9.46365 14.3213 12.0144C14.1127 13.0972 13.7078 13.4582 13.3152 13.4943C12.4564 13.5665 11.8062 12.9408 10.9842 12.4114C9.68367 11.5812 8.95981 11.0639 7.69612 10.2457C6.23613 9.30724 7.18083 8.78987 8.01511 7.94765C8.23595 7.73108 12.0025 4.36219 12.0761 4.0614C12.0884 4.0253 12.0884 3.88092 12.0025 3.80873C11.9166 3.73654 11.7939 3.7606 11.6958 3.78467C11.5608 3.80873 9.49963 5.15629 5.48773 7.8153C4.89883 8.21235 4.37127 8.40486 3.89279 8.39283C3.36523 8.38079 2.35919 8.10406 1.59852 7.86343C0.67836 7.57467 -0.0577693 7.41825 0.00357478 6.91292C0.0403812 6.64822 0.408445 6.38352 1.0955 6.10679Z" fill="white"/>
                            </svg>
                            {text[lang].search.text24}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </TelegramScreen>
    );
};

export default Search;