import React, {useEffect, useState} from 'react';
import classes from "./payment.module.css";
import {useTelegram} from "../../../../hooks/useTelegram";
import {
    get_course,
    get_day_price_all,
    get_info,
    get_item,
    invoice_create,
    new_client,
    server
} from "../../../../utils/api";
import TelegramScreen from "../../../common/telegramScreen/telegramScreen";
import {text} from "../../../translate";
import {useNavigate} from "react-router-dom";
import {toRub} from "../../../../utils/money";
import {useCloudStorage} from "../../../../hooks/useCloudStorage";
import Loading from "../../../common/loading/loading";
import {getPrizesMas} from "../cart/cart";

const Payment = ({
                    openPaymentMethod,
                    setOpenPaymentMethod,
                    paymentMethod,
                    setPaymentMethod,
                    setDeliveryMethod,
                    setDeliveryAddress,
                    deliveryAddress,
                    deliveryMethod,
                    infoPhone,
                    setInfoPhone,
                    infoName,
                    setInfoName,
                    infoComment,
                    setInfoComment
    }) => {
    const {lang, webApp, user} = useTelegram()
    const navigate = useNavigate();
    const [items, setItems] = useState([])
    const [prizes, setPrizes] = useState([])
    const [course, setCourse] = useState(100)
    const [total, setTotal] = useState(0)
    const [isFull, setIsFull] = useState(false)
    const [address, setAddress] = useState({})
    const [link, setLink] = useState('')
    const [loading, setLoading] = useState(false)
    const [paymentCoff, setPaymentCoff] = useState(1)
    const {removeAll} = useCloudStorage()

    useEffect(()=>{
        const getCourse = async ()=>{
            const res = await get_course()
            setCourse(res+4)
        }
        const getAddress = async ()=>{
            const res = await get_info()
            setAddress(res)
        }
        getCourse()
        getAddress()
        getBasket()
    },[])

    useEffect(()=>{
        if(paymentMethod !== 0 &&
            (deliveryMethod === 1 || (deliveryMethod === 2 && deliveryAddress !== '')) &&
            infoName !== ''){
            setIsFull(true)
        }else{
            setIsFull(false)
        }
        switch (paymentMethod){
            case 2: setPaymentCoff(1.2); break
            case 3: setPaymentCoff(1.13); break
            default: setPaymentCoff(1); break
        }
    },[paymentMethod, deliveryMethod, deliveryAddress, infoName])

    async function getBasket(){
        webApp.CloudStorage.getKeys((e, value)=>{
            webApp.CloudStorage.getItems(value,async (e, value2)=>{
                const keys = Object.keys(value2)
                let basket = []
                let prizesTemp = []
                let totalT = 0
                for(let i = 0; i < keys.length; i++){
                    if(keys[i].toString().split('_').length === 2){
                        prizesTemp.push({
                            id:keys[i].toString().split('_')[0],
                            price:parseInt(keys[i].toString().split('_')[1]),
                            count:parseInt(value2[keys[i]])})
                    }else {
                        const res = await get_item(keys[i])
                        if (res) {
                            basket.push({item: res, count: parseInt(value2[keys[i]])})
                            totalT += res.price * parseInt(value2[keys[i]])
                        }
                    }
                }
                setItems(basket)

                const result = await getPrizesMas(user)
                let res2 = [];
                result.forEach(i =>{
                    let temp = prizesTemp.filter(j=>(j.id.toString()===i.item.id.toString() && j.price===i.price))
                    if(temp.length > 0){
                        res2.push({item: i.item, count: temp[0].count, price: i.price, maxCount: i.maxCount})
                        totalT += (i.item.price - i.price) * temp[0].count
                    }
                })
                setPrizes(res2)

                setTotal(totalT)
            })
        })
    }

    async function startPay(){
        setLoading(true)
        if(isFull){
            if(paymentMethod === 1){
                const res = await invoice_create(
                    infoName,
                    infoPhone,
                    paymentMethod,
                    deliveryAddress,
                    infoComment,
                    items,
                    webApp.initDataUnsafe?.user.username,
                    webApp.initDataUnsafe?.user.id,
                    course,
                    prizes
                )
                if(res.status === 'OK'){
                    setLink(res.result)
                    webApp.openLink(res.result)
                    setOpenPaymentMethod(5)
                    removeAll()
                }else{
                    webApp.showAlert('Ошибка оплаты, обратитесь пожалуйста к администратору')
                }
            }
            if(paymentMethod > 1){
                await new_client(
                    infoName,
                    infoPhone,
                    paymentMethod,
                    deliveryAddress,
                    infoComment,
                    items,
                    webApp.initDataUnsafe?.user.username,
                    webApp.initDataUnsafe?.user.id,
                    course,
                    prizes)
                setOpenPaymentMethod(4)
                removeAll()
            }
        }
        setLoading(false)
    }

    return (
        <TelegramScreen showbackbutton={true}>
            <div className={classes.mainBlock}>
                <div className={classes.block1}>
                    <div className={classes.text1}>{text[lang].payment.text1} #</div>
                    <div className={classes.itemsBlock}>
                        {items.map(item=>
                            <div className={classes.block}>
                                <div className={classes.blockLeft}>
                                    <div className={classes.imageBlock}>
                                        <img src={server+item.item.img} alt={''} className={classes.image}/>
                                    </div>
                                    <div>
                                        <div className={classes.name}>{item.item.firma} <span style={{whiteSpace:'nowrap', marginRight:'5px'}}>{item.item.name}</span></div>
                                        <div className={classes.hashrate}>{text[lang].payment.text2} <span className={classes.hashrate2}>{item.item.hashrate}</span></div>
                                        <div className={classes.hashrate}>{text[lang].payment.text32} <span className={classes.hashrate2}>{item.count}</span></div>
                                    </div>
                                </div>
                                <div className={classes.blockRight}>
                                    {lang === 'en' ? <div className={classes.price}>{(item.item.price * item.count).toFixed(0)} USDT</div> :
                                        <>
                                            <div className={classes.price}>{toRub((item.item.price * course * item.count).toFixed(0))} руб</div>
                                            <div className={classes.price2}>{(item.item.price * item.count).toFixed(0)} USDT</div>
                                        </>
                                    }
                                </div>
                            </div>
                        )}
                        {prizes.map(item=>
                            <div className={classes.block}>
                                <div className={classes.blockLeft}>
                                    <div className={classes.imageBlock}>
                                        <img src={server+item.item.img} alt={''} className={classes.image}/>
                                    </div>
                                    <div>
                                        <div className={classes.name}>{item.item.firma} <span style={{whiteSpace:'nowrap', marginRight:'5px'}}>{item.item.name}</span><span className={classes.prizePrice}>-${item.price}</span></div>
                                        <div className={classes.hashrate}>{text[lang].payment.text2} <span className={classes.hashrate2}>{item.item.hashrate}</span></div>
                                        <div className={classes.hashrate}>{text[lang].payment.text32} <span className={classes.hashrate2}>{item.count}</span></div>
                                    </div>
                                </div>
                                <div className={classes.blockRight}>
                                    {lang === 'en' ? <div className={classes.price}>{((item.item.price - item.price) * item.count).toFixed(0)} USDT</div> :
                                        <>
                                            <div className={classes.price} style={{color:'#D5414F'}}>{toRub(((item.item.price - item.price) * course * item.count).toFixed(0))} руб</div>
                                            <div className={classes.price2}>{((item.item.price - item.price) * item.count).toFixed(0)} USDT</div>
                                        </>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.totalBlock}>
                        <div className={classes.price3}>{text[lang].payment.text3}</div>
                        <div>
                            {lang === 'en' ? <div className={classes.price3}>{(total * paymentCoff).toFixed(0)} USDT</div> :
                                <>
                                    <div className={classes.price3}>{toRub((total * course * paymentCoff).toFixed(0))} руб</div>
                                    <div className={classes.price2}>{(total * paymentCoff).toFixed(0)} USDT</div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className={classes.block2}>
                    <div className={classes.block2_1} onClick={()=>setOpenPaymentMethod(1)}>
                        <div className={classes.text2}>{text[lang].payment.text4}
                            {paymentMethod === 1 && <div className={classes.text5}>{text[lang].payment.text10}</div>}
                            {paymentMethod === 2 && <div className={classes.text5}>{text[lang].payment.text11}</div>}
                            {paymentMethod === 3 && <div className={classes.text5}>{text[lang].payment.text12}</div>}
                            {paymentMethod === 4 && <div className={classes.text5}>{text[lang].payment.text13}</div>}
                            {paymentMethod === 5 && <div className={classes.text5}>{text[lang].payment.text10_1}</div>}
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                            <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#C8C7CA"/>
                        </svg>
                    </div>
                    <div className={classes.block2_1} onClick={()=>setOpenPaymentMethod(2)}>
                        <div className={classes.text2}>{text[lang].payment.text5}
                            {deliveryMethod === 1 && <div className={classes.text5}>{text[lang].payment.text16}</div>}
                            {deliveryMethod === 2 && <div className={classes.text5}>{deliveryAddress}</div>}
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                            <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#C8C7CA"/>
                        </svg>
                    </div>
                    <div className={classes.block2_1} style={{borderBottom:'none'}} onClick={()=>setOpenPaymentMethod(3)}>
                        <div className={classes.text2}>{text[lang].payment.text6}
                            <div className={classes.text5}>{infoName}</div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                            <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#C8C7CA"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className={classes.footer}>
                <div className={classes.cancel} onClick={()=>{
                    navigate(-1)
                    webApp.MainButton.show();
                }}>{text[lang].payment.text7}</div>
                <div className={isFull?classes.pay2:classes.pay}
                    onClick={startPay}
                >{paymentMethod === 1 ? text[lang].payment.text8 : text[lang].payment.text27}</div>
            </div>
            {openPaymentMethod === 1 &&
                <div className={classes.popup}>
                    <div className={classes.mainBlock}>
                        <div className={classes.text3}>{text[lang].payment.text9}</div>
                        <div className={classes.paymentMethods}>
                            {false && <div className={classes.paymentMethod} onClick={()=>setPaymentMethod(1)}>
                                <div className={classes.text4}>{text[lang].payment.text10}</div>
                                <input className={classes.radio} type={'radio'} checked={paymentMethod===1} onChange={()=>setPaymentMethod(1)}/>
                            </div>}
                            <div className={classes.paymentMethod} onClick={()=>setPaymentMethod(5)} style={{borderBottom:'none'}}>
                                <div className={classes.text4}>{text[lang].payment.text10_1}</div>
                                <input className={classes.radio} type={'radio'} checked={paymentMethod===5} onChange={()=>setPaymentMethod(5)}/>
                            </div>
                            <div className={classes.paymentMethod} onClick={()=>setPaymentMethod(2)}>
                                <div className={classes.text4}>{text[lang].payment.text11}</div>
                                <input className={classes.radio} type={'radio'} checked={paymentMethod===2} onChange={()=>setPaymentMethod(2)}/>
                            </div>
                            <div className={classes.paymentMethod} onClick={()=>setPaymentMethod(3)}>
                                <div className={classes.text4}>{text[lang].payment.text12}</div>
                                <input className={classes.radio} type={'radio'} checked={paymentMethod===3} onChange={()=>setPaymentMethod(3)}/>
                            </div>
                            <div className={classes.paymentMethod} onClick={()=>setPaymentMethod(4)} style={{borderBottom:'none'}}>
                                <div className={classes.text4}>{text[lang].payment.text13}</div>
                                <input className={classes.radio} type={'radio'} checked={paymentMethod===4} onChange={()=>setPaymentMethod(4)}/>
                            </div>
                        </div>
                    </div>
                    <div className={classes.footer2} onClick={()=>{
                        setOpenPaymentMethod(openPaymentMethod === 3 ? 0 : openPaymentMethod+1)
                    }}>
                        {text[lang].payment.text14}
                    </div>
                </div>
            }
            {openPaymentMethod === 2 &&
                <div className={classes.popup}>
                    <div className={classes.mainBlock}>
                        <div className={classes.text3}>{text[lang].payment.text15}</div>
                        <div className={classes.paymentMethods}>
                            <div className={classes.paymentMethod} onClick={()=>setDeliveryMethod(1)}>
                                <div className={classes.text4}>{text[lang].payment.text16}
                                    <div className={classes.hashrate}>{lang === 'ru' ? address.address_RU : address.address_EN}</div>
                                </div>
                                <input className={classes.radio} type={'radio'} checked={deliveryMethod===1} onChange={()=>setDeliveryMethod(1)}/>
                            </div>
                            <div className={classes.paymentMethod} onClick={()=>setDeliveryMethod(2)}>
                                <div className={classes.text4}>{text[lang].payment.text18}</div>
                                <input className={classes.radio} type={'radio'} checked={deliveryMethod===2} onChange={()=>setDeliveryMethod(2)}/>
                            </div>
                        </div>
                    </div>
                    <div className={classes.footer2} onClick={()=>{
                        setOpenPaymentMethod(openPaymentMethod === 3 ? 0 : openPaymentMethod+1)
                    }}>
                        {text[lang].payment.text14}
                    </div>
                </div>
            }
            {openPaymentMethod === 3 &&
                <div className={classes.popup}>
                    <div className={classes.mainBlock}>
                        <div className={classes.text3}>{text[lang].payment.text19}</div>
                        <div className={classes.paymentMethods}>
                            <div className={classes.paymentMethod}>
                                <div className={classes.text4}>{text[lang].payment.text20}
                                </div>
                                <input className={classes.input}
                                       type={'text'}
                                       placeholder={text[lang].payment.text21}
                                       value={infoName}
                                       onChange={(e)=>setInfoName(e.target.value)}/>
                            </div>
                            <div className={classes.paymentMethod}>
                                <div className={classes.text4}>{text[lang].payment.text22}
                                </div>
                                <input className={classes.input}
                                       type={'tel'}
                                       placeholder={text[lang].payment.text22}
                                       value={infoPhone}
                                       onChange={(e)=>setInfoPhone(e.target.value)}/>
                            </div>
                        </div>
                        {deliveryMethod === 2 &&
                            <>
                                <div className={classes.text3}>{text[lang].payment.text23}</div>
                                <div className={classes.paymentMethods}>
                                    <div className={classes.paymentMethod}>
                                        <div className={classes.text4}>{text[lang].payment.text24}
                                        </div>
                                        <input className={classes.input}
                                               type={'text'}
                                               placeholder={text[lang].payment.text23}
                                               value={deliveryAddress}
                                               onChange={(e)=>setDeliveryAddress(e.target.value)}/>
                                    </div>
                                </div>
                                <div className={classes.text3}>{text[lang].payment.text25}</div>
                                <textarea className={classes.textarea} placeholder={text[lang].payment.text26} value={infoComment} onChange={(e)=>setInfoComment(e.target.value)}></textarea>
                            </>
                        }
                    </div>
                    <div className={classes.footer2} onClick={()=>{
                        setOpenPaymentMethod(openPaymentMethod === 3 ? 0 : openPaymentMethod+1)
                    }}>
                        {text[lang].payment.text14}
                    </div>
                </div>
            }
            {openPaymentMethod === 4 &&
                <div className={classes.popup}>
                    <div className={classes.mainBlock2}>
                        <div className={classes.text6}>{text[lang].payment.text28}</div>
                        <div className={classes.text7}>{text[lang].payment.text29}</div>
                    </div>
                    <div className={classes.footer2} onClick={()=>{window.location.href='/'}}>
                        {text[lang].payment.text30}
                    </div>
                </div>
            }
            {openPaymentMethod === 5 &&
                <div className={classes.popup}>
                    <div className={classes.mainBlock2}>
                        <div className={classes.text6}>{text[lang].payment.text33}</div>
                        <div className={classes.text7}>{text[lang].payment.text34}</div>
                    </div>
                    <div className={classes.footer2} onClick={()=>{webApp.openLink(link)}}>
                        {text[lang].payment.text8}
                    </div>
                </div>
            }
            <Loading isOpen={loading}/>
        </TelegramScreen>
    );
};

export default Payment;