import './App.css';
import {useCallback, useEffect, useState} from "react";
import Main from "./components/pages/main/main";
import {Route, Routes, useNavigate} from "react-router-dom";
import Shop from "./components/pages/shop/shop";
import {useTelegram} from "./hooks/useTelegram";
import Models from "./components/pages/shop/models/models";
import {text} from "./components/translate";
import Cart from "./components/pages/shop/cart/cart";
import Payment from "./components/pages/shop/payment/payment";
import Calc from "./components/pages/calc/calc";
import Invoice from "./components/pages/invoice/invoice";
import {tracking1, tracking4} from "./utils/api";
import Search from "./components/pages/search/search";

function App() {
  const {webApp, lang} = useTelegram()
  const navigate = useNavigate();
  const [openPaymentMethod, setOpenPaymentMethod] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState(0)
  const [deliveryMethod, setDeliveryMethod] = useState(0)
  const [deliveryAddress, setDeliveryAddress] = useState('')
  const [infoPhone, setInfoPhone] = useState('')
  const [infoName, setInfoName] = useState('')
  const [infoComment, setInfoComment] = useState('')
  const [timer, setTimer] = useState()
  const [startParams, setStartParams] = useState({})
  const [getStartParams, setGetStartParams] = useState(true)

  const [step, setStep] = useState(0)
  const [stepSearch, setStepSearch] = useState(0)

  useEffect(()=>{
    if(window.location.pathname === '/payment'){
      if(openPaymentMethod > 0)
        setOpenPaymentMethod(0)
      else{
        navigate(-1)
        webApp.MainButton.show()
      }
    }
    if(window.location.pathname === '/calc'){
      if(step === 0){
        navigate(-1)
      }
      if(step === 5){
        setStep(0)
      }
      if(step > 0 && step < 5){
        setStep(step-1)
      }
      if(step === 6){
        setStep(5)
      }
    }
    if(window.location.pathname === '/search'){
      if(stepSearch === 0){
        navigate(-1)
      }
      if(stepSearch === 1 || stepSearch === 2 || stepSearch === 3){
        setStepSearch(0)
      }
    }
  },[timer])

  useEffect(()=>{
    if(window.location.pathname === '/'){
      webApp.MainButton.hide()
    }
  },[navigate])

  useEffect(()=>{
    const track = async ()=>{
      const res = await tracking4(
          webApp.initDataUnsafe?.user.id,
          paymentMethod
      )
      console.log(res)
    }
    if(paymentMethod > 0){
      track()
    }
  },[paymentMethod])


  const onBackClick = useCallback(() => {
    if(window.location.pathname === '/calc'){
      setTimer(new Date)
      return
    }
    if(window.location.pathname === '/search'){
      setTimer(new Date)
      return
    }
    if(window.location.pathname === '/cart'){
      webApp.MainButton.setText(text[lang].shop.text3)
    }
    if(window.location.pathname === '/payment'){
      setTimer(new Date)
      return
      //webApp.MainButton.show()
    }
    if(window.location.pathname === '/shop/models'){
      webApp.MainButton.hide()
    }
    navigate(-1)
  },[navigate])

  const onMainClick = useCallback(() => {
    if(window.location.pathname === '/shop/models'){
      navigate('/cart')
      webApp.MainButton.setText(text[lang].cart.text3)
      return;
    }
    if(window.location.pathname === '/cart'){
      navigate('/payment')
      webApp.MainButton.hide()
      return;
    }
  }, [navigate])

  useEffect(() => {
    if(webApp.platform !== 'unknown'){
      webApp.ready()
      webApp.enableClosingConfirmation()
      webApp.expand()
      webApp.BackButton.onClick(onBackClick)
      webApp.MainButton.setText(text[lang].shop.text3)
      webApp.MainButton.onClick(onMainClick)
      const track = async ()=>{
        const res = await tracking1(
            webApp.initDataUnsafe?.user.first_name+' '+webApp.initDataUnsafe?.user.last_name,
            webApp.initDataUnsafe?.user.username,
            webApp.initDataUnsafe?.user.id
        )
        console.log(res)
      }
      track()
      return () => {
        webApp.BackButton.offClick(onBackClick)
        webApp.MainButton.offClick(onMainClick)
      };
    }
  }, [webApp])

  useEffect(()=>{
    const hash = window.location.hash.slice(1);
    const params = new URLSearchParams(hash);
    const tgWebAppData = new URLSearchParams(params.get('tgWebAppData'));
    const startParamsT = tgWebAppData.get('start_param');
    if(startParamsT && getStartParams){
      if(startParamsT.split('=')[0] === 'calc'){
        setStartParams({el:startParamsT.split('=')[1], items:startParamsT.split('=')[2]})
        setGetStartParams(false)
        navigate('calc')
      }
      if(startParamsT.split('=')[0] === 'prizes'){
        setStartParams({com:startParamsT.split('=')[1]})
        setGetStartParams(false)
      }
      if(startParamsT.split('=')[0] === 'search'){
        setStartParams({
          el:startParamsT.split('=')[1],
          budget:startParamsT.split('=')[2],
          energy:startParamsT.split('=')[3]
        })
        setGetStartParams(false)
        navigate('search')
      }
    }
  },[])

  return (
      <div className="App">
        <Routes>
          <Route index element={<Main startParams={startParams} setStartParams={setStartParams}/>}/>
          <Route path='/calc' element={<Calc step={step} setStep={setStep} startParams={startParams} setStartParams={setStartParams}/>}/>
          <Route path='/shop' element={<Shop/>}/>
          <Route path='/shop/models' element={<Models/>}/>
          <Route path='/cart' element={<Cart/>}/>
          <Route path='/payment' element={<Payment
              openPaymentMethod={openPaymentMethod}
              setOpenPaymentMethod={setOpenPaymentMethod}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              deliveryMethod={deliveryMethod}
              setDeliveryMethod={setDeliveryMethod}
              deliveryAddress={deliveryAddress}
              setDeliveryAddress={setDeliveryAddress}
              infoPhone={infoPhone}
              setInfoPhone={setInfoPhone}
              infoName={infoName}
              setInfoName={setInfoName}
              infoComment={infoComment}
              setInfoComment={setInfoComment}
          />}/>
          <Route path='/account' element={<Invoice/>}/>
          <Route path='/search' element={<Search step={stepSearch}
                                                 setStep={setStepSearch}
                                                 startParams={startParams}
                                                 setStartParams={setStartParams}
          />}/>
        </Routes>
      </div>
  )
}
export default App;
