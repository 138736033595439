import React, {useEffect, useState} from 'react';
import {get_day_price_all} from "../../../utils/api";
import {useTelegram} from "../../../hooks/useTelegram";
import classes from "./prizeFooter.module.css";
import {text} from "../../translate";
import {useNavigate} from "react-router-dom";
import {getPrizes} from "../../pages/shop/cart/cart";

const PrizeFooter = () => {
    const {user, lang, webApp} = useTelegram()
    const [prizes, setPrizes] = useState([])
    const [day, setDay] = useState(0)
    const navigate = useNavigate();

    useEffect(()=>{
        const getPrizes = async ()=>{
            const res = await get_day_price_all(user?.id ? user.id: null)
            setPrizes(res)
            console.log(res)
        }
        getPrizes()
        const d = new Date();
        setDay(d.getDay())
    },[])

    const stylesDays = [
        {color: "#4F5267", gradient: 'linear-gradient(270deg, #979BAE 0%, #46495E 100%)'},
        {color: "#D5414F", gradient: 'linear-gradient(89deg, #D23C4C 0%, #E04E57 50.5%, #F3B255 100%)'},
        {color: "#E5643C", gradient: 'linear-gradient(270deg, #FF965E 0%, #E6621B 100%)'},
        {color: "#E5932F", gradient: 'linear-gradient(270deg, #ECC55D 0%, #E48D2C 100%)'},
        {color: "#47B032", gradient: 'linear-gradient(270deg, #85D164 0%, #40AD2C 100%)'},
        {color: "#4235DF", gradient: 'linear-gradient(270deg, #8970E9 0%, #392EDE 100%)'},
        {color: "#2667D7", gradient: 'linear-gradient(270deg, #5E9FE1 0%, #1F60D6 100%)'}
    ]

    async function goCart(){
        await getPrizes(webApp, user)
        navigate('/cart?key=prize');
        webApp.MainButton.show();
        webApp.MainButton.setText(text[lang].cart.text3)
    }

    return (
        <div className={classes._}
             style={{transform:prizes.length>0?'translateY(0)':'translateY(64px)', background:stylesDays[day].gradient}}
             onClick={goCart}
        >
            <div className={classes.prizeBlock1}>
                <div className={classes.prizeImgBlock}>
                    <div className={classes.prizeImg} style={{color:stylesDays[day].color}}>
                        {prizes.length}
                    </div>
                </div>
                <div className={classes.prizeBlockTextBlock}>
                    <div className={classes.prizeBlockText1}>{text[lang].prizeDay.text11}</div>
                </div>
            </div>
            <div className={classes.prizeCountBlock}>
                <div className={classes.prizeStr}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 18L15 12L9 6" stroke="white" strokeWidth="2" strokeLinecap="square"/>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default PrizeFooter;