import React from 'react';
import classes from "./popup.module.css";
import {CSSTransition} from "react-transition-group";

const Popup2 = ({openPopup, setOpenPopup, price}) => {
    return (
        <CSSTransition in={openPopup}
                       timeout={300}
                       classNames="block"
                       unmountOnExit
        >
            <div className={classes.popup} onClick={()=>setOpenPopup(false)}>
                <div className={classes.close} onClick={()=>setOpenPopup(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M15 5L5 15M5 5L15 15" stroke={'var(--tg-theme-text-color)'} strokeOpacity="0.8" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className={classes.popupBlock}>
                    <div className={classes.popupText1}>Все условия и положения</div>
                    <div className={classes.popupText2}>
                        <div><span style={{fontWeight:700}}>1. Общие положения</span><br/>
                            1.1. Игра "Рулетка IMINE" (далее — "Игра") проводится компанией <span style={{fontWeight:700}}>IMINE</span> (далее — "Организатор") в рамках Телеграм мини-приложения с целью привлечения пользователей к покупкам в магазине <span style={{fontWeight:700}}>IMINE</span>, а также предоставления скидок на товары.<br/>
                        1.2. Участвуя в Игре, пользователи соглашаются с настоящими условиями и положениями, а также с правилами обработки персональных данных, указанными на сайте Организатора.<br/><br/>
                        </div>
                        <div>
                            <span style={{fontWeight:700}}> 2. Условия участия</span><br/>
                        2.1. Участие в Игре доступно всем зарегистрированным пользователям в Телеграм, которые достигли возраста 18 лет.<br/>
                        2.2. Для участия в Игре необходимо:<br/>
                            - Быть зарегистрированным пользователем на платформе <span style={{fontWeight:700}}>IMINE</span> в Телеграм мини-приложении.<br/>
                        - Иметь достаточное количество звезд для участия в рулетке, если это предусмотрено системой.<br/>
                        - Ознакомиться и согласиться с настоящими условиями и положениями.<br/>
                            2.3. Игра проводится исключительно в Телеграм мини-приложении <span style={{fontWeight:700}}>IMINE</span>, которое доступно для всех пользователей в мессенджере Телеграм.<br/><br/>
                        </div>
                        <div><span style={{fontWeight:700}}>3. Призы и выигрыши</span><br/>
                            3.1. В качестве призов в Игре разыгрываются ваучеры на скидки на покупку ASIC-майнеров в магазине <span style={{fontWeight:700}}>IMINE</span>.<br/>
                        3.2. Номиналы выигрыша:<br/>
                        - $25<br/>
                        - $40<br/>
                        - $50<br/>
                        - $60<br/>
                        - $80<br/>
                        - $100<br/>
                        3.3. Каждый выигрыш в виде ваучера на скидку имеет ограничения по использованию:<br/>
                            - Ваучер можно использовать только при покупке товаров, указанных “списке призов”  магазина <span style={{fontWeight:700}}>IMINE</span>.<br/>
                            <span style={{fontWeight:700}}>- Один ваучер действителен только в рамках заказа одной единицы оборудования.</span> Другими словами <span style={{fontWeight:700}}>Один прокрут колеса = получение скидки на 1шт (ASIC-майнер)</span>. Нельзя получить суммарную скидку со всех прокрутов колеса на 1-у единицу оборудования. Воспользоваться полученной скидкой можно в течение заданного времени, указанного на начальном экране предложения.<br/>
                        - Применение скидки через ваучер ограничено минимальной суммой покупки, установленной на момент выигрыша.<br/><br/>
                        </div>
                        <div><span style={{fontWeight:700}}>4. Правила игры</span><br/>
                            4.1. Игра осуществляется в Телеграм мини-приложении <span style={{fontWeight:700}}>IMINE</span>, где участники могут вращать рулетку для получения ваучеров на скидки.<br/>
                        4.2. Участники могут прокрутить рулетку, потратив звезды. Стоимость прокрута в звездах определяется Организатором и может изменяться в зависимости от условий, установленных для каждого периода.<br/>
                        4.3. Каждое вращение рулетки требует наличия определенного количества звезд на аккаунте. Стоимость прокрута в звездах будет изменяться по усмотрению Организатора, и участники будут уведомлены о стоимости перед каждым прокрутом.<br/>
                        4.4. Рулетка состоит из различных секторов, каждый из которых соответствует определенному номиналу ваучера на скидку. Приз определяется случайным образом в момент вращения рулетки.<br/>
                        4.5. Результат вращения рулетки является окончательным и не подлежит пересмотру. Организатор не несет ответственности за технические сбои или ошибки при проведении игры, если они не являются результатом намеренных действий.<br/>
                        4.6 Все выигранные ваучеры (скидки) доступны и могут быть использованы в течение определенного времени, которое показано системой на первом экране приложения.<br/>
                        4.7 В некоторых случаях ваучеры (скидки) будут доступны и на следующий день после окончания акции. По этим вопросам следует обращаться к сотрудникам компании (менеджерам)<br/><br/>
                        </div>
                        <div><span style={{fontWeight:700}}>5. Ответственная игра</span><br/>
                            5.1. Игра носит развлекательный характер и предназначена для того, чтобы пользователи могли получить бонусы и скидки на покупку товаров в <span style={{fontWeight:700}}>IMINE</span>.<br/>
                        5.2. Участникам рекомендуется соблюдать умеренность при участии в игре, не тратя больше средств и времени, чем они готовы потерять.<br/>
                        5.3. Организатор оставляет за собой право ограничить доступ к игре для пользователей, которые злоупотребляют участием или нарушают правила игры.<br/><br/>
                        </div>
                        <div><span style={{fontWeight:700}}>6. Прочие положения</span><br/>
                            6.1. Организатор оставляет за собой право изменять условия игры, включая количество доступных выигрышей, номиналы ваучеров, стоимость прокрута в звездах и другие параметры, не уведомляя участников заранее. Все изменения вступают в силу с момента их публикации в мини-приложении <span style={{fontWeight:700}}>IMINE</span>.<br/>
                        6.2. Все участники игры обязаны следовать законам и нормативным актам своей страны проживания.<br/>
                            6.3. Участники игры соглашаются с тем, что их персональные данные могут быть использованы для целей игры и маркетинга в соответствии с политикой конфиденциальности <span style={{fontWeight:700}}>IMINE</span>.<br/>
                        6.4. Организатор оставляет за собой право дисквалифицировать участников, которые будут замечены в манипуляциях с системой игры или нарушении других условий.<br/>
                        </div>
                    </div>
                </div>
            </div>
            
        </CSSTransition>
    );
};

export default Popup2;